.label,
.radio {
  position: relative;
}
.radio {
  cursor: pointer;
  margin: 0;
  height: 20px;
  width: 20px;
  border-radius: var(--br-81xl);
  background-color: var(--color-neutral-white);
  border: 1px solid var(--link-primary);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
}
.label {
  line-height: 150%;
}
.selectedfalse {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.label1,
.radio1 {
  position: relative;
}
.radio1 {
  cursor: pointer;
  margin: 0;
  height: 20px;
  width: 20px;
  border-radius: var(--br-81xl);
  background-color: var(--color-neutral-white);
  border: 1px solid var(--link-primary);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
}
.label1 {
  line-height: 150%;
}
.selectedfalse1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.label2,
.radio2 {
  position: relative;
}
.radio2 {
  cursor: pointer;
  margin: 0;
  height: 20px;
  width: 20px;
  border-radius: var(--br-81xl);
  background-color: var(--color-neutral-white);
  border: 1px solid var(--link-primary);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
}
.label2 {
  line-height: 150%;
}
.column,
.selectedfalse2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.column {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-sm);
  min-width: 242px;
  max-width: 100%;
  text-align: left;
  font-size: var(--text-regular-normal-size);
  color: var(--link-primary);
  font-family: var(--text-small-link);
}
