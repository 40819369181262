.home1 {
  flex: 1;
  background: linear-gradient(180deg, #faf7fa, #c9c1ec);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.home {
  width: 100%;
  position: relative;
  background-color: var(--color-neutral-white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  letter-spacing: normal;
}

@media screen and (max-width: 800px) {
  .home {
    gap: var(--gap-45xl);
  }
}

@media screen and (max-width: 450px) {
  .home {
    gap: var(--gap-13xl);
    padding-top: var(--padding-6xl); /* Ajoute un padding en haut pour éviter que le contenu soit caché */
  }
}
