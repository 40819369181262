.placeholderImageIcon {
  height: 640px;
  flex: 1;
  position: relative;
  border-radius: var(--br-71xl);
  max-width: 200%;
  overflow: hidden;
  object-fit: cover;
  min-width: 400px;
}

.heading,
.iconInstagram {
  align-self: stretch;
  position: relative;
}
.heading {
  margin: 0;
  height: auto; /* Ajusté pour ne pas couvrir le texte */
  font-size: inherit;
  line-height: 120%;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  margin-bottom: var(--gap-base); /* Ajout d'une marge inférieure */
}
.iconInstagram {
  font-size: var(--text-medium-normal-size);
  line-height: 150%;
}
.content {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-xl) 0;
  box-sizing: border-box;
  gap: var(--gap-5xl);
  min-width: 400px;
  max-width: 100%;
}
.container,
.content,
.layout194,
.otherServices {
  display: flex;
  justify-content: flex-start;
}
.container {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--gap-61xl);
  max-width: 100%;
}
.layout194,
.otherServices {
  overflow: hidden;
  flex-direction: column;
}
.layout194 {
  align-self: stretch;
  align-items: center;
  padding: var(--padding-93xl) var(--padding-45xl);
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: var(--heading-desktop-h3-size);
  color: var(--link-primary);
  font-family: var(--text-small-link);
}
.otherServices {
  width: 100%;
  position: relative;
  background: linear-gradient(180deg, #ffe4c3, #f6f5f4);
  align-items: flex-start;
  letter-spacing: normal;
}
@media screen and (max-width: 1125px) {
  .layout194 {
    padding-top: var(--padding-54xl);
    padding-bottom: var(--padding-54xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 800px) {
  .placeholderImageIcon {
    min-width: 100%;
  }
  .heading {
    font-size: var(--font-size-13xl);
    line-height: 38px;
    margin-bottom: var(--gap-base); /* Ajout d'une marge inférieure */
  }
  .content {
    min-width: 100%;
  }
  .container,
  .layout194 {
    gap: var(--gap-21xl);
  }
  .layout194 {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .heading {
    font-size: var(--font-size-5xl);
    line-height: 29px;
    margin-bottom: var(--gap-base); /* Ajout d'une marge inférieure */
  }
  .container,
  .layout194 {
    gap: var(--gap-xl);
  }
  .layout194 {
    padding-top: var(--padding-28xl);
    padding-bottom: var(--padding-28xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .layout194 {
    padding-top: var(--padding-6xl); /* Ajoute un padding en haut pour éviter que le contenu soit caché */
  }
}


@media screen and (max-width: 1105px) {
  .otherServices {
    gap: var(--gap-13xl);
    padding-top: var(--padding-6xl); /* Ajoute un padding en haut pour éviter que le contenu soit caché */
  }
}
@media screen and (max-width: 450px) {
  .otherServices {
    gap: var(--gap-13xl);
    padding-top: var(--padding-45xl); /* Ajoute un padding en haut pour éviter que le contenu soit caché */
  }
}