.heading {
  margin: 0;
  height: 116px;
  position: relative;
  font-size: inherit;
  line-height: 120%;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
}
.content1,
.fAQ,
.heading {
  align-self: stretch;
}
.fAQ {
  position: relative;
  font-size: var(--text-medium-normal-size);
  line-height: 150%;
}
.content1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.placeholder {
  width: 100%;
  border: 0;
  outline: 0;
  font-family: var(--text-small-link);
  font-size: var(--text-regular-normal-size);
  background-color: transparent;
  height: 24px;
  flex: 1;
  position: relative;
  line-height: 150%;
  color: var(--color-neutral-neutral);
  text-align: left;
  display: inline-block;
  min-width: 222px;
  max-width: 100%;
}
.typedefault {
  flex: 1;
  background-color: var(--color-neutral-white);
  border: 1px solid var(--link-primary);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-smi) var(--padding-xs)
    var(--padding-2xs);
  max-width: calc(100% - 121px);
}
.button {
  position: relative;
  line-height: 150%;
}
.styleprimarySmallfalseDa {
  height: 50px;
  background-color: var(--link-primary);
  border: 1px solid var(--link-primary);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-4xs);
  white-space: nowrap;
}
.form {
  align-self: stretch;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  max-width: 100%;
}
.byClickingSign {
  align-self: stretch;
  position: relative;
  font-size: var(--text-tiny-normal-size);
  line-height: 150%;
  color: var(--link-primary);
  text-align: center;
}
.actions {
  width: 513px;
  padding: var(--padding-base) 0 0;
  box-sizing: border-box;
  gap: var(--gap-base);
  text-align: left;
  font-size: var(--text-regular-normal-size);
  color: var(--color-neutral-white);
}
.actions,
.content,
.cta26 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
.content {
  width: 768px;
  gap: var(--gap-5xl);
}
.cta26 {
  align-self: stretch;
  border-radius: var(--br-71xl);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0), #c9c1ec);
  padding: var(--padding-93xl) var(--padding-45xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--heading-desktop-h2-size);
  color: var(--link-primary);
  font-family: var(--text-small-link);
}
@media screen and (max-width: 1125px) {
  .cta26 {
    gap: var(--gap-21xl);
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 800px) {
  .heading {
    font-size: var(--font-size-19xl);
    line-height: 46px;
  }
  .cta26 {
    padding-top: var(--padding-54xl);
    padding-bottom: var(--padding-54xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .heading {
    font-size: var(--font-size-10xl);
    line-height: 35px;
  }
  .cta26 {
    gap: var(--gap-xl);
  }
}
