/* Pour les écrans au-dessus de 1000px */
@media screen and (min-width: 1000px) {
  .mediumLengthHero {
    margin: 0;
    align-self: stretch;
    height: auto; /* Permet à la hauteur de s'adapter au contenu */
    position: relative;
    font-size: inherit;
    line-height: 120%;
    font-weight: 700;
    font-family: inherit;
    display: inline-block;
  }

  /* Responsiveness */
  @media screen and (max-width: 1325px) {
    .mediumLengthHero {
      font-size: var(--font-size-37xl); /* Ajuster la taille du texte */
      line-height: 48px; /* Ajuster la hauteur de ligne */
    }
  }

  @media screen and (max-width: 800px) {
    .mediumLengthHero {
      font-size: var(--font-size-26xl); /* Taille du texte pour les écrans moyens */
      line-height: 54px; /* Ajuster la hauteur de ligne pour correspondre */
    }

    .header15 {
      padding-top: var(--padding-10xl); /* Ajoute un padding en haut */
      padding-left: var(--padding-l); /* Ajuste le padding à gauche */
      padding-right: var(--padding-13xl); /* Ajuste le padding à droite */
      box-sizing: border-box;
    }

    .column,
    .column1 {
      min-width: 100%;
    }

    .content,
    .header15 {
      gap: var(--gap-21xl);
    }

    .placeholderImageIcon {
      width: 100%;
      aspect-ratio: 4 / 3; /* Maintient un ratio de 4:3 pour l'image */
      height: auto; /* Permet à l'image de s'ajuster à la hauteur basée sur le ratio */
      object-fit: cover; /* L'image couvre l'espace tout en respectant son ratio */
      border-radius: var(--br-71xl);
    }
  }

  @media screen and (max-width: 450px) {
    .mediumLengthHero {
      font-size: var(--font-size-15xl); /* Taille du texte pour les petits écrans */
      line-height: 40px; /* Ajuster la hauteur de ligne pour correspondre */
      padding-top: var(--padding-10xl); /* Ajoute un padding en haut */
      padding-left: 5px; /* Ajuste le padding à gauche */
      padding-right: var(--padding-13xl); /* Ajuste le padding à droite */
    }

    .content,
    .header15 {
      gap: var(--gap-xl);
    }
  }

  /* Rest of your CSS */
  .column {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 400px;
    max-width: 100%;
  }

  .loremIpsumDolor {
    align-self: stretch;
    position: relative;
    line-height: 150%;
    padding-left: 5px; /* Ajuste le padding à gauche */
    padding-right: var(--padding-45xl); /* Ajuste le padding à droite */
  }

  .column1,
  .content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .column1 {
    flex: 1;
    flex-direction: column;
    gap: var(--gap-5xl);
    min-width: 400px;
    max-width: 100%;
    font-size: var(--text-medium-normal-size);
  }

  .content {
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap-61xl);
  }

  .content,
  .header15,
  .placeholderImageIcon {
    align-self: stretch;
    max-width: 100%;
  }

  .placeholderImageIcon {
    position: relative;
    border-radius: var(--br-71xl);
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
  }

  .header15 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-5xl) var(--padding-5xl);
    box-sizing: border-box;
    gap: var(--gap-61xl);
    text-align: left;
    font-size: var(--font-size-37xl);
    color: var(--link-primary);
    font-family: var(--text-small-link);
  }

  @media screen and (max-width: 1325px) {
    .header15 {
      padding-top: var(--padding-54xl);
      padding-bottom: var(--padding-54xl);
      box-sizing: border-box;
    }
  }
}

/* Pour les écrans en dessous de 1000px */
@media screen and (max-width: 1000px) {
  .mediumLengthHero {
    margin: 0;
    align-self: stretch;
    height: auto; /* Permet à la hauteur de s'adapter au contenu */
    position: relative;
    font-size: inherit;
    line-height: 120%;
    font-weight: 700;
    font-family: inherit;
    display: inline-block;
    max-width: 100%; /* Limite la largeur maximale à 100% du conteneur parent */
    overflow-x: hidden; /* Empêche le défilement horizontal */
  }

  /* Responsiveness */
  @media screen and (max-width: 1325px) {
    .mediumLengthHero {
      font-size: var(--font-size-37xl); /* Ajuster la taille du texte */
      line-height: 48px; /* Ajuster la hauteur de ligne */
    }
  }

  @media screen and (max-width: 800px) {
    .mediumLengthHero {
      font-size: var(--font-size-26xl); /* Taille du texte pour les écrans moyens */
      line-height: 54px; /* Ajuster la hauteur de ligne pour correspondre */
    }

    .header15 {
      padding-top: var(--padding-10xl); /* Ajoute un padding en haut */
      padding-left: var(--padding-l); /* Ajuste le padding à gauche */
      padding-right: var(--padding-13xl); /* Ajuste le padding à droite */
      box-sizing: border-box;
      max-width: 100%; /* Limite la largeur maximale à 100% du conteneur parent */
      overflow-x: hidden; /* Empêche le défilement horizontal */
    }

    .column,
    .column1 {
      min-width: 100%;
    }

    .content,
    .header15 {
      gap: var(--gap-21xl);
    }

    .placeholderImageIcon {
      width: 100%;
      aspect-ratio: 4 / 3; /* Maintient un ratio de 4:3 pour l'image */
      height: auto; /* Permet à l'image de s'ajuster à la hauteur basée sur le ratio */
      object-fit: cover; /* L'image couvre l'espace tout en respectant son ratio */
      border-radius: var(--br-71xl);
    }
  }

  @media screen and (max-width: 450px) {
    .mediumLengthHero {
      font-size: var(--font-size-15xl); /* Taille du texte pour les petits écrans */
      line-height: 40px; /* Ajuster la hauteur de ligne pour correspondre */
      padding-top: var(--padding-10xl); /* Ajoute un padding en haut */
      padding-left: 5px; /* Ajuste le padding à gauche */
      padding-right: var(--padding-13xl); /* Ajuste le padding à droite */
      max-width: 100%; /* Limite la largeur maximale à 100% du conteneur parent */
      overflow-x: hidden; /* Empêche le défilement horizontal */
    }

    .content,
    .header15 {
      gap: var(--gap-xl);
    }
  }

  /* Empêche les éléments de déborder du conteneur parent */
  .column,
  .column1,
  .loremIpsumDolor,
  .header15,
  .content,
  .placeholderImageIcon {
    max-width: 100%; /* Limite la largeur maximale à 100% du conteneur parent */
    overflow-x: hidden; /* Empêche le défilement horizontal */
  }

  .loremIpsumDolor {
    align-self: stretch;
    position: relative;
    line-height: 150%;
    padding-left: 5px; /* Ajuste le padding à gauche */
    padding-right: var(--padding-45xl); /* Ajuste le padding à droite */
    max-width: 100%; /* Limite la largeur maximale à 100% du conteneur parent */
    overflow-x: hidden; /* Empêche le défilement horizontal */
  }

  .column1 {
    flex: 1;
    flex-direction: column;
    gap: var(--gap-5xl);
    min-width: 400px;
    max-width: 100%; /* Limite la largeur maximale à 100% du conteneur parent */
    font-size: var(--text-medium-normal-size);
    overflow-x: hidden; /* Empêche le défilement horizontal */
  }

  .content {
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap-61xl);
    overflow-x: hidden; /* Empêche le défilement horizontal */
  }

  .placeholderImageIcon {
    position: relative;
    border-radius: var(--br-71xl);
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
    max-width: 100%; /* Limite la largeur maximale à 100% du conteneur parent */
  }

  .header15 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-5xl) var(--padding-5xl);
    box-sizing: border-box;
    gap: var(--gap-61xl);
    text-align: left;
    font-size: var(--font-size-37xl);
    color: var(--link-primary);
    font-family: var(--text-small-link);
    max-width: 100%; /* Limite la largeur maximale à 100% du conteneur parent */
    overflow-x: hidden; /* Empêche le défilement horizontal */
  }

  @media screen and (max-width: 1325px) {
    .header15 {
      padding-top: var(--padding-54xl);
      padding-bottom: var(--padding-54xl);
      box-sizing: border-box;
    }
  }
}

/* Blinking cursor animation */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blinkingCursor {
  display: inline-block;
  width: 0.2ch;
  height: 1em;
  background-color: black;
  animation: blink 1s step-end infinite;
}
