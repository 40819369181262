.contentFrame,
.heading {
  align-self: stretch;
  position: relative;
}

.heading {
  margin: 0;
  margin-bottom: var(--gap-xl); /* Espace proportionnel entre la heading et le contenu suivant */
  font-size: inherit;
  line-height: 120%;
  font-weight: 700;
  font-family: inherit;
}

.contentFrame {
  font-size: var(--text-medium-normal-size);
  line-height: 150%;
  gap: var(--gap-5xl); /* Espace proportionnel entre .contentFrame et .list */
}

.sectionTitle {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}

.iconRelume {
  width: 48px;
  height: 48px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.formInputField,
.subheadingOne {
  align-self: stretch;
  position: relative;
  line-height: 140%;
}

.formInputField {
  font-size: var(--text-regular-normal-size);
  line-height: 150%;
}

.listItem {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  min-width: 192px;
}

.iconRelume1 {
  width: 48px;
  height: 48px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.creditsDivider,
.subheadingTwo {
  align-self: stretch;
  position: relative;
  line-height: 140%;
}

.creditsDivider {
  font-size: var(--text-regular-normal-size);
  line-height: 150%;
}

.list,
.listItem1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.listItem1 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-base);
  min-width: 192px;
}

.list {
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  gap: var(--gap-5xl);
  font-size: var(--heading-desktop-h6-size);
}

.content,
.placeholderImageIcon {
  flex: 1;
  max-width: 100%;
  min-width: 400px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) 0;
  box-sizing: border-box;
  gap: var(--gap-13xl);
}

.placeholderImageIcon {
  height: 640px;
  position: relative;
  border-radius: var(--br-71xl);
  overflow: hidden;
  object-fit: cover;
}

.container,
.layout12 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.container {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--gap-61xl);
}

.layout12 {
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-93xl) var(--padding-45xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--heading-desktop-h3-size);
  color: var(--link-primary);
  font-family: var(--text-small-link);
}

@media screen and (max-width: 1125px) {
  .layout12 {
    padding-top: var(--padding-54xl);
    padding-bottom: var(--padding-54xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 1010px) {
  .heading {
    margin-bottom: 2px; /* Réduit l'écart sous le heading à 2 pixels */
  }

  .sectionTitle {
    gap: 4px; /* Réduit l'écart entre les éléments dans .sectionTitle */
  }
}
@media screen and (max-width: 800px) {
  .heading {
    font-size: var(--font-size-13xl);
    line-height: 38px;
  }
  .list {
    flex-wrap: wrap;
  }
  .content {
    gap: var(--gap-base);
  }
  .content,
  .placeholderImageIcon {
    min-width: 100%;
  }
  .container,
  .layout12 {
    gap: var(--gap-21xl);
  }
  .layout12 {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .placeholderImageIcon {
    height: auto;
    width: 100%;
    aspect-ratio: 3 / 4; /* Maintient un ratio de 3:4 pour le conteneur de l'image */
    object-fit: cover; /* Redimensionne l'image tout en conservant son ratio */
    border-radius: var(--br-71xl);
  }
  .heading {
    font-size: var(--font-size-13xl);
    line-height: 29px;
    
  }
  .subheadingOne,
  .subheadingTwo {
    font-size: var(--text-regular-normal-size);
    line-height: 22px;
  }
  .container,
  .layout12 {
    gap: var(--gap-xl);
  }
  .layout12 {
    padding-top: var(--padding-28xl);
    padding-bottom: var(--padding-28xl);
    box-sizing: border-box;
  }
}
