.iconRelume {
  width: 48px;
  height: 48px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.form,
.heading {
  position: relative;
  display: inline-block;
}
.heading {
  margin: 0;
  align-self: stretch;
  height: 68px;
  font-size: inherit;
  line-height: 140%;
  font-weight: 700;
  font-family: inherit;
}
.form {
  height: 72px;
  font-size: var(--text-regular-normal-size);
  line-height: 150%;
}
.content,
.sectionTitle {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.content {
  align-items: center;
}
.button {
  position: relative;
  line-height: 150%;
  white-space: nowrap;
}
.iconChevronRight {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}
.stylelinkSmallfalseDark {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.action,
.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.action {
  align-self: stretch;
  padding: var(--padding-5xs) 0 0;
  text-align: left;
  font-size: var(--text-regular-normal-size);
}
.column {
  flex: 1;
  gap: var(--gap-5xl);
  min-width: 304px;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-5xl);
  color: var(--link-primary);
  font-family: var(--text-small-link);
}
@media screen and (max-width: 450px) {
  .heading {
    font-size: var(--font-size-lgi);
    line-height: 27px;
  }
}
