.colorDark {
  height: 27px;
  width: 63px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 0;
}

.column {
  width: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) var(--padding-8xs);
  box-sizing: border-box;
  background-image: url(/public/column5@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.linkAlt,
.linkOne,
.linkThree,
.linkTwo {
  height: 24px;
  position: relative;
  line-height: 150%;
  display: inline-block;
}

.column2 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  max-width: 100%;
}

.button {
  position: relative;
  line-height: 150%;
}

.stylesecondarySmalltrueD {
  height: 42px;
  border: 1px solid var(--link-primary);
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-xl);
}

.button1 {
  position: relative;
  line-height: 150%;
}

.actions,
.styleprimarySmalltrueDar {
  border-radius: var(--br-71xl);
  display: flex;
  flex-direction: row;
}

.styleprimarySmalltrueDar {
  height: 42px;
  background-color: var(--link-primary);
  border: 1px solid var(--link-primary);
  box-sizing: border-box;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-xl);
  color: var(--color-neutral-white);
}

.actions {
  height: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}

.column1,
.content,
.navbar11 {
  align-items: center;
  max-width: 100%;
  position: fixed;
}

.column1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-base);
}

.content,
.navbar11 {
  align-self: stretch;
  position: fixed;
}

.content {
  border-bottom: 1px solid var(--link-primary);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--padding-smi) var(--padding-41xl);
  min-height: 72px;
  gap: var(--gap-xl);
}

.navbar11 {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.89),
    rgba(255, 255, 255, 0)
  );
  display: none;
  position: fixed;
  flex-direction: column;
  justify-content: flex-start;
}

.colorDark1,
.column3 {
  height: 27px;
  overflow: hidden;
}

.colorDark1 {
  width: 63px;
  position: relative;
  flex-shrink: 0;
  display: none;
}

.column3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-image: url(/public/column4@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-width: 151px;
}

.columnOne {
  align-self: stretch;
  height: 24px;
  position: relative;
  line-height: 150%;
  font-weight: 600;
  display: inline-block;
}

.link,
.linkOne1 {
  align-self: stretch;
  flex: 1;
}

.linkOne1 {
  position: relative;
  line-height: 150%;
}

.link {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}

.linkTwo1 {
  position: relative;
  line-height: 150%;
}

.link1,
.linkThree1,
.linkTwo1 {
  align-self: stretch;
  flex: 1;
}

.link1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}

.linkThree1 {
  position: relative;
  line-height: 150%;
}

.link2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}

.link2,
.link3,
.linkFour {
  align-self: stretch;
  flex: 1;
}

.linkFour {
  position: relative;
  line-height: 150%;
}

.link3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-11xl) var(--padding-5xs) 0;
}

.linkFive {
  flex: 1;
  position: relative;
  line-height: 150%;
}

.column4,
.footerLinks,
.link4 {
  align-items: flex-start;
  justify-content: flex-start;
}

.link4 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
}

.column4,
.footerLinks {
  display: flex;
  flex-direction: column;
}

.footerLinks {
  align-self: stretch;
  height: 148px;
  font-size: var(--text-small-link-size);
}

.column4 {
  flex: 1;
  overflow: hidden;
  gap: var(--gap-base);
  min-width: 151px;
}

.columnTwo,
.linkSix {
  position: relative;
  line-height: 150%;
}

.columnTwo {
  align-self: stretch;
  height: 24px;
  font-weight: 600;
  display: inline-block;
}

.linkSix {
  flex: 1;
}

.link5 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}

.linkSeven {
  flex: 1;
  position: relative;
  line-height: 150%;
}

.link6 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}

.link7,
.linkEight {
  align-self: stretch;
  flex: 1;
}

.linkEight {
  position: relative;
  line-height: 150%;
}

.link7 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}

.linkNine {
  flex: 1;
  position: relative;
  line-height: 150%;
}

.link8,
.linkNine,
.linkTen {
  align-self: stretch;
}

.link8 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}

.linkTen {
  width: 141px;
  position: relative;
  line-height: 150%;
  display: none;
}

.column5,
.footerLinks1,
.link9 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.link9 {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
}

.column5,
.footerLinks1 {
  flex-direction: column;
}

.footerLinks1 {
  align-self: stretch;
  height: 111px;
  font-size: var(--text-small-link-size);
}

.column5 {
  flex: 1;
  overflow: hidden;
  gap: var(--gap-base);
  min-width: 151px;
}

.columnThree {
  align-self: stretch;
  height: 24px;
  font-weight: 600;
  display: inline-block;
}

.columnThree,
.linkEleven {
  position: relative;
  line-height: 150%;
}

.link10,
.linkEleven,
.linkTwelve {
  align-self: stretch;
  flex: 1;
}

.link10 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}

.linkTwelve {
  position: relative;
  line-height: 150%;
}

.link11 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}

.link11,
.link12,
.linkThirteen {
  align-self: stretch;
  flex: 1;
}

.linkThirteen {
  position: relative;
  line-height: 150%;
}

.link12 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}

.linkFourteen {
  align-self: stretch;
  width: 171px;
  position: relative;
  line-height: 150%;
  display: inline-block;
  flex-shrink: 0;
}

.link13,
.linkFifteen {
  align-self: stretch;
  flex: 1;
}

.link13 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}

.linkFifteen {
  position: relative;
  line-height: 150%;
}

.footerLinks2,
.link14 {
  align-self: stretch;
  flex: 1;
  display: flex;
}

.link14 {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}

.footerLinks2 {
  flex-direction: column;
  font-size: var(--text-small-link-size);
}

.column6,
.footerLinks2,
.links {
  align-items: flex-start;
  justify-content: flex-start;
}

.column6 {
  height: 225px;
  width: 141px;
  overflow: hidden;
  display: none;
  flex-direction: column;
  gap: var(--gap-base);
}

.links {
  width: 684px;
  display: flex;
  flex-direction: row;
  gap: var(--gap-21xl);
  min-width: 684px;
  max-width: 100%;
}

.subscribe {
  font-weight: 600;
}

.joinOurNewsletter,
.subscribe {
  align-self: stretch;
  height: 24px;
  position: relative;
  line-height: 150%;
  display: inline-block;
}

.subscribeParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}

.placeholder {
  height: 24px;
  flex: 1;
  position: relative;
  line-height: 150%;
  display: inline-block;
  max-width: 100%;
}

.typedefault {
  flex: 1;
  background-color: var(--color-neutral-white);
  border: 1px solid var(--link-primary);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-sm) var(--padding-xs)
    var(--padding-xs);
  min-height: 50px;
  max-width: calc(100% - 137px);
  flex-shrink: 0;
  white-space: nowrap;
}

.button2 {
  flex: 1;
  position: relative;
  line-height: 150%;
}

.stylesecondarySmallfalse {
  height: 50px;
  width: 121px;
  border: 1px solid var(--link-primary);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  flex-shrink: 0;
  color: var(--link-primary);
}

.form {
  align-self: stretch;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  max-width: 100%;
}

.bySubscribingYou {
  align-self: stretch;
  height: 36px;
  position: relative;
  font-size: var(--text-tiny-normal-size);
  line-height: 150%;
  color: var(--link-primary);
  display: inline-block;
}

.actions1 {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-base);
  color: var(--color-neutral-neutral);
}

.actions1,
.content1,
.newslatter {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.newslatter {
  width: 500px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  min-width: 500px;
}

.content1 {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  min-height: 248px;
  gap: var(--gap-xl);
}

.divider {
  align-self: stretch;
  height: 1px;
  position: relative;
  background-color: var(--link-primary);
  border: 1px solid var(--link-primary);
  box-sizing: border-box;
}

.cookiesSettings,
.privacyPolicy,
.relumeAllRights,
.termsOfService {
  height: 21px;
  position: relative;
  line-height: 150%;
  display: inline-block;
}

.cookiesSettings,
.privacyPolicy,
.termsOfService {
  text-decoration: underline;
}

.credits1,
.footerLinks3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  max-width: 100%;
}

.credits1 {
  align-items: center;
}

.iconFacebook,
.iconInstagram,
.iconLinkedin,
.iconX,
.iconYoutube {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.socialLinks {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}

.credits,
.row {
  align-self: stretch;
  display: flex;
  max-width: 100%;
}

.row {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.credits {
  gap: var(--gap-13xl);
  font-size: var(--text-small-link-size);
}

.aiDrivenChatbots,
.chatbots,
.credits,
.footer2 {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.footer2 {
  align-self: stretch;
  background-color: transparent;
  overflow: hidden;
  display: none;
  padding: var(--padding-61xl) var(--padding-45xl);
  box-sizing: border-box;
  gap: var(--gap-61xl);
  max-width: 100%;
}

.aiDrivenChatbots,
.chatbots {
  display: flex;
  background: linear-gradient(180deg, #a4b8f8, #f5f5f5);
}

.aiDrivenChatbots {
  align-self: stretch;
  background: linear-gradient(180deg, #a4b8f8, #f5f5f5);
  overflow: hidden;
  max-width: 100%;
  text-align: left;
  font-size: var(--text-regular-normal-size);
  color: var(--link-primary);
  font-family: var(--text-small-link);
}

.chatbots {
  width: 100%;
  position: relative;
  letter-spacing: normal;
}

@media screen and (max-width: 1325px) {
  .links,
  .newslatter {
    flex: 1;
  }

  .content1 {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1125px) {
  .content {
    flex-wrap: wrap;
  }

  .links {
    min-width: 100%;
  }

  .row {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 800px) {
  .column1,
  .column2,
  .links {
    flex-wrap: wrap;
  }

  .newslatter {
    min-width: 100%;
  }

  .credits1 {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 450px) {
  .footerLinks3 {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1200px) {
  .chatbots {
    padding-top: var(--padding-6xl); /* Ajoute un padding en haut pour descendre le contenu */
  }
}

@media screen and (min-width: 1200px) {
  .chatbots {
    padding-top: 125px; /* Ajoute un padding en haut pour descendre le contenu */
  }
}

/* Ajouter la mise en page en deux colonnes pour les écrans supérieurs à 1100px */
@media screen and (min-width: 1100px) {
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Assure un espace entre les deux colonnes */
    align-items: flex-start;
  }

  .mediumLengthHero {
    flex: 1;
    margin-right: var(--gap-xl); /* Ajoute un espace entre les colonnes */
  }

  .loremIpsumDolor {
    flex: 1;
    padding-left: 0; /* Réinitialise le padding à gauche pour aligner les colonnes */
    padding-right: 0; /* Réinitialise le padding à droite pour aligner les colonnes */
  }
}
