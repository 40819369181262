.layout121 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-93xl) var(--padding-45xl);
  box-sizing: border-box;
  max-width: 100%;
}
@media screen and (max-width: 1125px) {
  .layout121 {
    padding-top: var(--padding-54xl);
    padding-bottom: var(--padding-54xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 800px) {
  .layout121 {
    gap: var(--gap-21xl);
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .layout121 {
    gap: var(--gap-xl);
    padding-top: var(--padding-28xl);
    padding-bottom: var(--padding-28xl);
    box-sizing: border-box;
  }
}
