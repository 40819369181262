.heading,
.tagline {
  position: relative;
  line-height: 150%;
  font-weight: 600;
}
.heading {
  margin: 0;
  font-size: inherit;
  line-height: 120%;
  font-weight: 700;
  font-family: inherit;
}
.content,
.heading,
.text {
  align-self: stretch;
}
.text {
  position: relative;
  font-size: var(--text-medium-normal-size);
  line-height: 150%;
}
.content {
  align-items: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--heading-desktop-h2-size);
}
.contact,
.contact2,
.content,
.sectionTitle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.sectionTitle {
  width: 768px;
  align-items: center;
  gap: var(--gap-base);
  max-width: 100%;
}
.contact,
.contact2 {
  overflow: hidden;
}
.contact2 {
  align-self: stretch;
  align-items: center;
  padding: var(--padding-93xl) var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-29xl);
  max-width: 100%;
  text-align: center;
  font-size: var(--text-regular-normal-size);
  color: var(--link-primary);
  font-family: var(--text-small-link);
}
.contact {
  width: 100%;
  position: relative;
  background-color: #f5f5f5;
  align-items: flex-start;
  letter-spacing: normal;
}
@media screen and (max-width: 1325px) {
  .contact2 {
    padding-top: var(--padding-54xl);
    padding-bottom: var(--padding-54xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 800px) {
  .heading {
    font-size: var(--font-size-19xl);
    line-height: 46px;
  }
  .contact2 {
    padding-top: var(--padding-28xl);
    padding-bottom: var(--padding-28xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .heading {
    font-size: var(--font-size-10xl);
    line-height: 35px;
  }
  .contact2 {
    gap: var(--gap-5xl);
  }
}

@media screen and (max-width: 800px) {
  .contact2 {
    padding-top: 100px; /* Ajoute un padding en haut pour éviter que le contenu soit caché */
  }
}
