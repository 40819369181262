:root {
  --background-dark: #121212;
  --background-card: #1e1e1e;
  --text-light: #e0e0e0;
  --text-muted: #b0b0b0;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  padding: 20px; /* Padding ajouté pour l'espace à gauche et à droite */
  box-sizing: border-box; /* Inclusion du padding dans la largeur totale */
  margin: 0 auto; /* Centrage de la grille */
}

.card {
  display: flex;
  background: var(--background-card);
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  padding: 0;
  flex-direction: row-reverse;
  width: 100%;
}

.card:hover {
  transform: scale(1.05);
}

.articleImage {
  width: 50%;
  height: 100%;
  object-fit: cover;
}

.articleContent {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.articleTitle {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: var(--text-light);
  background: linear-gradient(45deg, #ff00ff, #87ffff, #4a4afc);
  background-size: 400% 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: iridescentScroll 10s ease infinite;
}

.articleText {
  font-size: 1rem;
  color: var(--text-muted);
}

.authorInfo {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.profileImage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.date {
  font-size: 0.7rem;
  color: var(--text-muted);
}

.header {
  grid-column: 1 / -1;
  text-align: left;
  padding: 0 20px; /* Ajout de padding horizontal pour le header */
  box-sizing: border-box; /* S'assure que le padding n'affecte pas la largeur totale */
}

.title {
  font-size: 3rem;
  color: var(--text-light);
  margin-bottom: 50px;
  background: linear-gradient(45deg, #f683f6, #00ffff, #ffffff, #5454ff);
  background-size: 400% 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: iridescentScroll 10s ease infinite;
}

.description {
  font-size: 1.2rem;
  color: var(--text-light);
  margin-bottom: 50px;
  background: linear-gradient(45deg, #ffffff, #ffffff, #ffffff, #ffffff);
  background-size: 400% 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: iridescentScroll 10s ease infinite;
}

/* Ajustements pour les écrans moyens */
@media (max-width: 992px) {
  .grid {
    grid-template-columns: repeat(auto-fill);
  }
  .articleTitle {
    font-size: 1.2rem;
  }
  .articleText {
    font-size: 0.9rem;
  }

  .articleImage {
    width: 50%;
    height: auto;
  }
}

/* Mise en page à une seule colonne en dessous de 800px */
@media (max-width: 859px) {
  .grid {
    grid-template-columns: 1fr;
  }
  .card {
    width: 100%;
  }
  .articleImage {
    width: 40%;
    height: auto;
  }

  .articleTitle {
    font-size: 2rem;
  }

  .articleText {
    font-size: 1.2rem;
  }
}


/* Mise en page à une seule colonne en dessous de 800px */
@media (max-width: 800px) {
  .grid {
    grid-template-columns: 1fr;
  }
  .card {
    width: 100%;
  }
  .articleImage {
    width: 40%;
    height: auto;
  }
  .articleTitle {
    font-size: 1.5rem;
  }

}

/* Mise en page à une seule colonne en dessous de 800px */
@media (max-width: 510px) {
  .grid {
    grid-template-columns: 1fr;
  }
  .card {
    width: 100%;
  }
  .articleImage {
    width: 40%;
    height: auto;
  }
  .articleTitle {
    font-size: 1.2rem;
  }
  .articleText {
    font-size: 0.9rem;
  }

}
