.subheading {
  width: 73px;
  position: relative;
  line-height: 150%;
  font-weight: 600;
  display: none;
}
.heading {
  margin: 0;
  align-self: stretch;
  height: 116px;
  position: relative;
  font-size: var(--heading-desktop-h2-size);
  line-height: 120%;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
}
.content1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.button {
  position: relative;
  font-size: var(--text-regular-normal-size);
  line-height: 150%;
  font-family: var(--text-small-link);
  color: var(--link-primary);
  text-align: left;
}
.stylesecondarySmallfalse {
  cursor: pointer;
  border: 1px solid var(--link-primary);
  padding: var(--padding-xs) var(--padding-2xl);
  background-color: transparent;
  border-radius: var(--br-71xl);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.stylesecondarySmallfalse:hover {
  background-color: var(--color-darkslategray-200);
  border: 1px solid var(--color-darkslategray-100);
  box-sizing: border-box;
}
.button1 {
  align-self: stretch;
  position: relative;
  line-height: 150%;
  white-space: nowrap;
}
.iconChevronRight {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}
.stylelinkSmallfalseDark {
  height: 24px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.actions,
.column {
  display: flex;
  justify-content: flex-start;
}
.actions {
  flex-direction: row;
  align-items: center;
  padding: var(--padding-base) 0 0;
  gap: var(--gap-5xl);
}
.column {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-13xl);
  min-width: 400px;
  max-width: 100%;
}
.iconRelume {
  width: 48px;
  height: 48px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.divider {
  width: 1px;
  flex: 1;
  position: relative;
  background-color: var(--link-primary);
  border-right: 2px solid var(--link-primary);
  box-sizing: border-box;
}
.content2 {
  height: 164px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.heading1,
.title {
  align-self: stretch;
  position: relative;
  line-height: 140%;
}
.title {
  font-size: var(--text-regular-normal-size);
  line-height: 150%;
}
.content3,
.timelineItem {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.content3 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-base);
  min-width: 343px;
}
.timelineItem {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-21xl);
}
.iconRelume1 {
  width: 48px;
  height: 48px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.divider1 {
  width: 1px;
  flex: 1;
  position: relative;
  background-color: var(--link-primary);
  border-right: 2px solid var(--link-primary);
  box-sizing: border-box;
}
.content4 {
  height: 164px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.heading2,
.text {
  align-self: stretch;
  position: relative;
  line-height: 140%;
}
.text {
  font-size: var(--text-regular-normal-size);
  line-height: 150%;
}
.content5,
.timelineItem1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.content5 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-base);
  min-width: 343px;
}
.timelineItem1 {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-21xl);
}
.iconRelume2 {
  width: 48px;
  height: 48px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.divider2 {
  width: 1px;
  flex: 1;
  position: relative;
  background-color: var(--link-primary);
  border-right: 2px solid var(--link-primary);
  box-sizing: border-box;
}
.content6 {
  height: 164px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.heading3,
.text1 {
  align-self: stretch;
  position: relative;
  line-height: 140%;
}
.text1 {
  font-size: var(--text-regular-normal-size);
  line-height: 150%;
}
.content7,
.timelineItem2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.content7 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-base);
  min-width: 343px;
}
.timelineItem2 {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-21xl);
}
.iconRelume3 {
  width: 48px;
  height: 48px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.content8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.heading4,
.privacyPolicyTerms {
  align-self: stretch;
  position: relative;
  line-height: 140%;
}
.privacyPolicyTerms {
  font-size: var(--text-regular-normal-size);
  line-height: 150%;
}
.content9 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-base);
  min-width: 343px;
}
.content,
.content9,
.timeline,
.timelineItem3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.timelineItem3 {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-21xl);
}
.content,
.timeline {
  flex: 1;
}
.timeline {
  flex-direction: column;
  gap: var(--gap-base);
  min-width: 400px;
  font-size: var(--heading-desktop-h6-size);
}
.content {
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap-61xl);
  text-align: left;
  font-size: var(--text-regular-normal-size);
  color: var(--link-primary);
  font-family: var(--text-small-link);
}
@media screen and (max-width: 800px) {
  .heading {
    font-size: var(--font-size-19xl);
    line-height: 46px;
  }
  .column {
    gap: var(--gap-base);
  }
  .column,
  .content3 {
    min-width: 100%;
  }
  .timelineItem {
    flex-wrap: wrap;
    gap: var(--gap-xl);
  }
  .content5 {
    min-width: 100%;
  }
  .timelineItem1 {
    flex-wrap: wrap;
    gap: var(--gap-xl);
  }
  .content7 {
    min-width: 100%;
  }
  .timelineItem2 {
    flex-wrap: wrap;
    gap: var(--gap-xl);
  }
  .content9 {
    min-width: 100%;
  }
  .timelineItem3 {
    flex-wrap: wrap;
    gap: var(--gap-xl);
  }
  .timeline {
    min-width: 100%;
  }
  .content {
    gap: var(--gap-21xl);
  }
}
@media screen and (max-width: 450px) {
  .heading {
    font-size: var(--font-size-10xl);
    line-height: 35px;
  }
  .heading1,
  .heading2,
  .heading3,
  .heading4 {
    font-size: var(--text-regular-normal-size);
    line-height: 22px;
  }
  .content {
    gap: var(--gap-xl);
  }
}
