.firstName {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}
.input,
.typedefault {
  display: flex;
  justify-content: flex-start;
}
.typedefault {
  border: 1px solid var(--link-primary);
  outline: 0;
  background-color: var(--color-neutral-white);
  align-self: stretch;
  height: 50px;
  border-radius: var(--br-71xl);
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-xs);
  min-width: 223px;
}
.input {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-5xs);
  min-width: 242px;
  max-width: 100%;
}
.lastName {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}
.input1,
.inputs,
.typedefault1 {
  display: flex;
  justify-content: flex-start;
}
.typedefault1 {
  border: 1px solid var(--link-primary);
  outline: 0;
  background-color: var(--color-neutral-white);
  align-self: stretch;
  height: 50px;
  border-radius: var(--br-71xl);
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-xs);
  min-width: 223px;
}
.input1,
.inputs {
  align-items: flex-start;
  max-width: 100%;
}
.input1 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-5xs);
  min-width: 242px;
}
.inputs {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap-5xl);
  flex-shrink: 0;
  text-align: left;
  font-size: var(--text-regular-normal-size);
  color: var(--link-primary);
  font-family: var(--text-small-link);
}
