.button {
  position: relative;
  font-size: var(--text-regular-normal-size);
  line-height: 150%;
  font-family: var(--text-small-link);
  color: var(--color-neutral-white);
  text-align: left;
}
.styleprimarySmallfalseDa {
  cursor: pointer;
  border: 1px solid var(--link-primary);
  padding: var(--padding-xs) var(--padding-4xl);
  background-color: var(--link-primary);
  border-radius: var(--br-71xl);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.styleprimarySmallfalseDa:hover {
  background-color: var(--color-darkslategray-100);
  border: 1px solid var(--color-darkslategray-100);
  box-sizing: border-box;
}
.button1 {
  position: relative;
  line-height: 150%;
}
.stylesecondarySmallfalse {
  height: 50px;
  border: 1px solid var(--link-primary);
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-mini);
}
.actions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base) 0 0;
  gap: var(--gap-base);
  text-align: left;
  font-size: var(--text-regular-normal-size);
  color: var(--link-primary);
  font-family: var(--text-small-link);
}
