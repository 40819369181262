.placeholderImageIcon {
  height: 640px;
  flex: 1;
  position: relative;
  border-radius: var(--br-71xl);
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  min-width: 400px;
}

.subheading {
  position: relative;
  line-height: 150%;
  font-weight: 600;
}

.heading,
.text {
  align-self: stretch;
  position: relative;
}

.heading {
  margin: 0;
  height: 116px;
  font-size: inherit;
  line-height: 120%;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
}

.text {
  font-size: var(--text-medium-normal-size);
  line-height: 150%;
}

.content2,
.sectionTitle {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.content2 {
  gap: var(--gap-5xl);
  text-align: left;
  font-size: var(--heading-desktop-h2-size);
}

.sectionTitle {
  gap: var(--gap-base);
}

.iconRelume {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.loremIpsumDolor {
  flex: 1;
  position: relative;
  line-height: 150%;
  display: inline-block;
  max-width: calc(100% - 40px);
}

.listItem {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  max-width: 100%;
}

.iconRelume1 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.loremIpsumDolor1 {
  flex: 1;
  position: relative;
  line-height: 150%;
  display: inline-block;
  max-width: calc(100% - 40px);
}

.listItem1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  max-width: 100%;
}

.iconRelume2 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.loremIpsumDolor2 {
  flex: 1;
  position: relative;
  line-height: 150%;
  display: inline-block;
  max-width: calc(100% - 40px);
}

.content1,
.list,
.listItem2 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.listItem2 {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-base);
}

.content1,
.list {
  flex-direction: column;
  align-items: flex-start;
}

.list {
  gap: var(--gap-base);
  text-align: left;
}

.content1 {
  gap: var(--gap-13xl);
}

.button {
  position: relative;
  font-size: var(--text-regular-normal-size);
  line-height: 150%;
  font-family: var(--text-small-link);
  color: var(--link-primary);
  text-align: left;
}

.stylesecondarySmallfalse {
  cursor: pointer;
  border: 1px solid var(--link-primary);
  padding: var(--padding-xs) var(--padding-2xl);
  background-color: transparent;
  border-radius: var(--br-71xl);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.stylesecondarySmallfalse:hover {
  background-color: var(--color-darkslategray-200);
  border: 1px solid var(--color-darkslategray-100);
  box-sizing: border-box;
}

.button1 {
  align-self: stretch;
  position: relative;
  line-height: 150%;
  white-space: nowrap;
}

.iconChevronRight {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.actions,
.stylelinkSmallfalseDark {
  flex-direction: row;
  align-items: center;
}

.stylelinkSmallfalseDark {
  height: 24px;
  display: none;
  justify-content: center;
  gap: var(--gap-5xs);
}

.actions {
  display: flex;
  justify-content: flex-start;
  padding: var(--padding-base) 0 0;
  gap: var(--gap-5xl);
  text-align: left;
}

.container,
.content,
.layout207 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.content {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-xl) 0;
  box-sizing: border-box;
  gap: var(--gap-5xl);
  min-width: 400px;
}

.container,
.layout207 {
  align-self: stretch;
  align-items: center;
}

.container {
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap-61xl);
}

.layout207 {
  border-radius: var(--br-71xl);
  background: linear-gradient(180deg, #fff, rgba(226, 209, 219, 0));
  flex-direction: column;
  padding: var(--padding-93xl) var(--padding-45xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--text-regular-normal-size);
  color: var(--link-primary);
  font-family: var(--text-small-link);
}

@media screen and (max-width: 1125px) {
  .layout207 {
    padding-top: var(--padding-54xl);
    padding-bottom: var(--padding-54xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 800px) {
  .placeholderImageIcon {
    min-width: 100%;
  }
  .heading {
    font-size: var(--font-size-19xl);
    line-height: 46px;
  }
  .content1 {
    gap: var(--gap-base);
  }
  .content {
    min-width: 100%;
  }
  .container,
  .layout207 {
    gap: var(--gap-21xl);
  }
  .layout207 {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .placeholderImageIcon {
    height: auto;
    width: 100%;
    aspect-ratio: 3 / 4; /* Maintient un ratio de 3:4 pour le conteneur de l'image */
    object-fit: cover; /* Redimensionne l'image tout en conservant son ratio */
    border-radius: var(--br-71xl);
  }
  .heading {
    font-size: var(--font-size-10xl);
    line-height: 35px;
  }
  .container,
  .layout207 {
    gap: var(--gap-xl);
  }
  .layout207 {
    padding-top: var(--padding-28xl);
    padding-bottom: var(--padding-28xl);
    box-sizing: border-box;
  }
}
