:root {
  /* fonts */
  --text-small-link: 'Roboto', sans-serif;

  /* font sizes */
  --text-small-link-size: 14px;
  --text-tiny-normal-size: 12px;
  --text-regular-normal-size: 16px;
  --text-medium-normal-size: 18px;
  --heading-desktop-h3-size: 40px;
  --font-size-13xl: 32px;
  --font-size-5xl: 24px;
  --font-size-lgi: 19px;
  --font-size-7xl: 26px;
  --font-size-37xl: 56px;
  --font-size-26xl: 45px;
  --font-size-15xl: 34px;
  --heading-desktop-h2-size: 48px;
  --font-size-19xl: 38px;
  --font-size-10xl: 29px;

  /* colors */
  --background-dark: #121212;
  --background-card: #1e1e1e;
  --text-light: #e0e0e0;
  --text-muted: #b0b0b0;
}

body {
  margin: 0;
  line-height: normal;
  font-family: var(--text-small-link);
  background-color: var(--background-dark);
  color: var(--text-light);
}

.blog {
  width: 100%;
  position: relative;
  background: var(--background-dark);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 80px;
}

.blogContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  width: 100%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 1200px;
}

.card {
  display: flex;
  background: var(--background-card);
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  padding: 0;
}

.card:hover {
  transform: scale(1.1);
}

.articleImage {
  width: 50%;
  height: auto;
  border-radius: 0;
  object-fit: cover;
}

.articleContent {
  flex: 1;
  padding: 20px;
}

.articleTitle {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.articleText {
  font-size: 1rem;
  color: var(--text-muted);
}

@media (max-width: 992px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .grid {
    grid-template-columns: 1fr;
  }
}

.navbar {
  z-index: 1002; /* S'assure que la barre de navigation est au-dessus du message de construction */
}


