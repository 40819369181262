.accordionItem,
.faqs {
  align-self: stretch;
  position: relative;
}

.faqs {
  margin: 0;
  font-size: inherit;
  line-height: 120%;
  font-weight: 700;
  font-family: inherit;
}

.accordionItem {
  font-size: var(--text-medium-normal-size);
  line-height: 150%;
}

.sectionTitle,
.sectionTitleWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.sectionTitle {
  width: 768px;
  flex-direction: column;
  gap: var(--gap-5xl);
}

.sectionTitleWrapper {
  align-self: stretch;
  flex-direction: row;
}

.divider {
  align-self: stretch;
  height: 1px;
  background-color: var(--link-primary);
  border: 1px solid var(--link-primary);
  box-sizing: border-box;
}

.divider,
.icon,
.question {
  position: relative;
}

.question {
  flex: 1;
  line-height: 150%;
  display: inline-block;
  min-width: 153px;
  max-width: 100%;
}

.icon {
  height: 32px;
  width: 32px;
  overflow: hidden;
  flex-shrink: 0;
}

.accordionItem1,
.title {
  display: flex;
  justify-content: flex-start;
}

.title {
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-xl) 0;
  box-sizing: border-box;
  gap: var(--gap-xs);
  max-width: 100%;
  cursor: pointer;
}

.accordionItem1 {
  width: 1312px;
  flex-direction: column;
  align-items: flex-start;
}

.divider1 {
  align-self: stretch;
  height: 1px;
  position: relative;
  background-color: var(--link-primary);
  border: 1px solid var(--link-primary);
  box-sizing: border-box;
}

.question1 {
  flex: 1;
  position: relative;
  line-height: 150%;
  display: inline-block;
  min-width: 153px;
  max-width: 100%;
}

.icon1 {
  height: 32px;
  width: 32px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.accordionItem2,
.title1 {
  display: flex;
  justify-content: flex-start;
}

.title1 {
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-xl) 0;
  box-sizing: border-box;
  gap: var(--gap-xs);
  max-width: 100%;
}

.accordionItem2 {
  width: 1312px;
  flex-direction: column;
  align-items: flex-start;
}

.divider2 {
  align-self: stretch;
  height: 1px;
  position: relative;
  background-color: var(--link-primary);
  border: 1px solid var(--link-primary);
  box-sizing: border-box;
}

.question2 {
  flex: 1;
  position: relative;
  line-height: 150%;
  display: inline-block;
  min-width: 153px;
  max-width: 100%;
}

.icon2 {
  height: 32px;
  width: 32px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.accordionItem3,
.title2 {
  display: flex;
  justify-content: flex-start;
}

.title2 {
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-xl) 0;
  box-sizing: border-box;
  gap: var(--gap-xs);
  max-width: 100%;
}

.accordionItem3 {
  width: 1312px;
  flex-direction: column;
  align-items: flex-start;
}

.divider3 {
  align-self: stretch;
  height: 1px;
  position: relative;
  background-color: var(--link-primary);
  border: 1px solid var(--link-primary);
  box-sizing: border-box;
}

.question3 {
  flex: 1;
  position: relative;
  line-height: 150%;
  display: inline-block;
  min-width: 153px;
  max-width: 100%;
}

.icon3 {
  height: 32px;
  width: 32px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.accordionItem4,
.title3 {
  display: flex;
  justify-content: flex-start;
}

.title3 {
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-xl) 0;
  box-sizing: border-box;
  gap: var(--gap-xs);
  max-width: 100%;
}

.accordionItem4 {
  width: 1312px;
  flex-direction: column;
  align-items: flex-start;
}

.divider4 {
  align-self: stretch;
  height: 1px;
  position: relative;
  background-color: var(--link-primary);
  border: 1px solid var(--link-primary);
  box-sizing: border-box;
}

.question4 {
  flex: 1;
  position: relative;
  line-height: 150%;
  display: inline-block;
  min-width: 153px;
  max-width: 100%;
}

.icon4 {
  height: 32px;
  width: 32px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.accordionItem5,
.title4 {
  display: flex;
  justify-content: flex-start;
}

.title4 {
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-xl) 0;
  box-sizing: border-box;
  gap: var(--gap-xs);
  max-width: 100%;
}

.accordionItem5 {
  width: 1312px;
  flex-direction: column;
  align-items: flex-start;
}

.divider5 {
  width: 1312px;
  height: 1px;
  position: relative;
  background-color: var(--link-primary);
  border: 1px solid var(--link-primary);
  box-sizing: border-box;
}

.accordion {
  width: 1312px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  font-size: var(--text-medium-normal-size);
}

.accordionItem6,
.stillHaveA {
  align-self: stretch;
  position: relative;
}

.stillHaveA {
  margin: 0;
  font-size: inherit;
  line-height: 130%;
  font-weight: 700;
  font-family: inherit;
}

.accordionItem6 {
  font-size: var(--text-medium-normal-size);
  line-height: 150%;
}

.content1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  flex-shrink: 0;
}

.button {
  position: relative;
  font-size: var(--text-regular-normal-size);
  line-height: 150%;
  font-family: var(--text-small-link);
  color: var(--link-primary);
  text-align: left;
  white-space: nowrap; /* Empêche les retours à la ligne */
  padding: 0 50px; /* Augmente le padding horizontal pour plus d'espace autour du texte */
  display: inline-flex; /* S'assure que le bouton est traité comme un élément en ligne flexible et s'adapte au contenu */
  align-items: center; /* Centre le texte verticalement */
  justify-content: center; /* Centre le texte horizontalement */
  min-width: max-content; /* S'assure que la largeur minimale correspond au contenu intérieur */
}

.stylesecondarySmallfalse {
  cursor: pointer;
  border: 1px solid var(--link-primary);
  padding: var(--padding-xs);
  background-color: transparent;
  width: 106px;
  height: 50px;
  border-radius: var(--br-71xl);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  white-space: nowrap; /* Empêche les retours à la ligne */
  padding: 0 var(--padding-xsm); /* Augmente le padding horizontal pour plus d'espace autour du texte */
  display: inline-flex; /* S'assure que le bouton est traité comme un élément en ligne flexible et s'adapte au contenu */
  align-items: center; /* Centre le texte verticalement */
  justify-content: center; /* Centre le texte horizontalement */
  min-width: max-content; /* S'assure que la largeur minimale correspond au contenu intérieur */
  flex-direction: row;
}

.stylesecondarySmallfalse:hover {
  background-color: var(--color-darkslategray-200);
  border: 1px solid var(--color-darkslategray-100);
  box-sizing: border-box;
}

.content,
.contentArea,
.faq2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.content {
  width: 560px;
  flex-direction: column;
  gap: var(--gap-5xl);
}

.contentArea,
.faq2 {
  align-self: stretch;
  flex-direction: row;
  font-size: var(--font-size-13xl);
}

.faq2 {
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-93xl) var(--padding-45xl);
  box-sizing: border-box;
  gap: var(--gap-61xl);
  text-align: left;
  font-size: var(--heading-desktop-h2-size);
  color: var(--link-primary);
  font-family: var(--text-small-link);
}

.answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease;
  opacity: 0;
  padding: 0 var(--padding-xl);
}

.open {
  max-height: 100px; /* Ajustez cette valeur en fonction du contenu */
  opacity: 1;
  padding: var(--padding-xs) var(--padding-xl);
}

@media screen and (max-width: 1125px) {
  .faq2 {
    padding-top: var(--padding-54xl);
    padding-bottom: var(--padding-54xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 800px) {
  .faqs {
    font-size: var(--font-size-19xl);
    line-height: 46px;
  }

  .stillHaveA {
    font-size: var(--font-size-7xl);
    line-height: 33px;
  }

  .faq2 {
    gap: var(--gap-21xl);
    padding: var(--padding-28xl) var(--padding-13xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .faqs {
    font-size: var(--font-size-10xl);
    line-height: 35px;
  }

  .stillHaveA {
    font-size: var(--font-size-lgi);
    line-height: 25px;
  }

  .faq2 {
    gap: var(--gap-xl);
  }
}
