@keyframes iridescent {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin-top: 30px;
  justify-content: center;
  position: relative;
}

.levelButton {
  background-color: black;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background 0.5s;
  position: relative;
  z-index: 10; /* Un z-index élevé pour les boutons */
}

.levelButton:hover {
  background-color: #333;
}

.levelButton.active {
  background: linear-gradient(45deg, #f683f6, #7b9cf0, #b6e4f8, #5454ff);
  color: black;
  background-size: 400% 400%;
  animation: iridescent 3s ease infinite;
}

.priceSubtitle {
  font-size: 0.75rem; /* Taille de police plus petite */
  color: #000000; /* Couleur plus douce */
  text-align: center; /* Centrer le texte */
  display: block; /* Assurez-vous qu'il est affiché en bloc */
  position: relative; /* Permet de déplacer l'élément par rapport à son parent */
  left: -42%; /* Positionne à 50% de la largeur du conteneur parent */
  transform: translateY(7px);
}

.subheading {
  position: relative;
  line-height: 150%;
  font-weight: 600;
}

.heading,
.textcontent {
  align-self: stretch;
  position: relative;
}

.heading {
  margin: 0;
  font-size: inherit;
  line-height: 120%;
  font-weight: 700;
  font-family: inherit;
}

.textcontent {
  font-size: var(--text-medium-normal-size);
  line-height: 150%;
  text-align: center;
}

.content,
.sectionTitle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.content {
  align-self: stretch;
  align-items: center;
  gap: var(--gap-5xl);
  font-size: var(--heading-desktop-h2-size);
}

.sectionTitle {
  width: 768px;
  align-items: center;
  gap: var(--gap-base);
  max-width: 100%;
}

.iconRelume {
  width: 48px;
  height: 48px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.heading1 {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 140%;
  font-weight: 700;
  font-family: inherit;
}

.linkthreefooter {
  position: relative;
  font-size: var(--text-regular-normal-size);
  line-height: 150%;
}

.priceTitle1,
.title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}

.priceTitle1 {
  gap: var(--gap-base);
  min-width: 268px;
}

.price,
.price1 {
  position: relative;
}

.price {
  line-height: 120%;
  white-space: nowrap;
  text-align: center;
}

.price1 {
  height: 42px;
  width: 0;
  font-size: var(--font-size-13xl);
  line-height: 130%;
  display: inline-block;
}

.container,
.priceTitle {
  display: flex;
  flex-direction: row;
  align-items: center; /* Aligner les éléments au centre verticalement */
  justify-content: space-between; /* Espace entre les éléments */
}

.container {
  align-items: flex-end;
  justify-content: flex-start;
  text-align: right;
  font-size: var(--font-size-37xl);
}

.priceTitle {
  align-self: stretch;
  align-items: center; /* Centrer les éléments */
  justify-content: space-between;
  gap: var(--gap-xl);
}

.divider,
.typedefaultplaceholder {
  align-self: stretch;
  position: relative;
}

.divider {
  height: 1px;
  border-top: 1px solid var(--link-primary);
  box-sizing: border-box;
}

.typedefaultplaceholder {
  line-height: 150%;
}

.content3,
.listItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.featureWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.featureColumn {
  flex: 1;
  padding: 10px;
}

.featureList {
  list-style-type: disc;
  padding-left: 20px;
}

.featureList li {
  margin-bottom: 10px;
}

.button,
.content2 {
  font-size: var(--text-regular-normal-size);
  text-align: left;
}

.content2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  max-width: 100%;
  flex-shrink: 0;
}

.button {
  position: relative;
  line-height: 150%;
  font-family: var(--text-small-link);
  color: var(--color-neutral-white);
  white-space: nowrap;
}

.styleprimarySmallFalseDa {
  cursor: pointer;
  border: none;
  padding: var(--padding-xs);
  background-color: black;
  color: white;
  width: 100%;
  max-width: 768px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--gap-5xl);
  border-radius: 5px;
}

.styleprimarySmallFalseDa:hover {
  background-color: #333;
}

.content1,
.pricing4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
  z-index: 1;
}

.content1 {
  width: 768px;
  border: 1px solid var(--link-primary);
  padding: var(--padding-13xl) var(--padding-12xl);
  gap: var(--gap-13xl);
  font-size: var(--font-size-5xl);
}

.pricing4 {
  align-self: stretch;
  border-radius: var(--br-71xl);
  background: linear-gradient(180deg, #fff, rgba(255, 255, 255, 0));
  padding: var(--padding-93xl) var(--padding-xl);
  gap: var(--gap-61xl);
  text-align: center;
  font-size: var(--text-regular-normal-size);
  color: var(--link-primary);
  font-family: var(--text-small-link);
  z-index: 1;
}

@media screen and (max-width: 1125px) {
  .pricing4 {
    gap: var(--gap-21xl);
    padding-top: var(--padding-54xl);
    padding-bottom: var(--padding-54xl);
    box-sizing: border-box;
    z-index: 1;
  }
}

@media screen and (max-width: 800px) {
  .heading {
    font-size: var(--font-size-19xl);
    line-height: 46px;
  }

  .priceTitle1 {
    flex: 1;
  }

  .price {
    font-size: var(--font-size-26xl);
    line-height: 54px;
  }

  .price1 {
    font-size: var(--font-size-7xl);
    line-height: 33px;
  }

  .priceTitle {
    flex-wrap: wrap;
  }

  .content1,
  .pricing4 {
    padding-top: var(--padding-2xl);
    padding-bottom: var(--padding-2xl);
    box-sizing: border-box;
    z-index: 1;
  }

  .pricing4 {
    padding-top: var(--padding-28xl);
    padding-bottom: var(--padding-28xl);
    z-index: 1;
  }
}

@media screen and (max-width: 530px) {
  .buttonGroup {
    flex-direction: column; /* Stack les boutons verticalement */
    gap: 15px; /* Réduit l'espace entre les boutons en mode vertical */
  }

  .levelButton {
    padding: 12px 20px; /* Ajuste la taille des boutons pour l'affichage vertical */
    font-size: 1rem;
    width: 100%; /* Prend toute la largeur du parent */
    text-align: center;
  }
}

@media screen and (max-width: 450px) {
  .heading {
    font-size: var(--font-size-10xl);
    line-height: 35px;
  }

  .heading1 {
    font-size: var(--font-size-lgi);
    line-height: 27px;
  }

  .price {
    font-size: var(--font-size-15xl);
    line-height: 40px;
  }

  .price1 {
    font-size: var(--font-size-lgi);
    line-height: 25px;
  }

  .content1 {
    gap: var(--gap-base);
  }

  .pricing4 {
    gap: var(--gap-xl);
    z-index: 1;
  }

  .levelButton {
    font-size: 0.9rem;
    padding: 10px 20px;
    max-width: 100%;
  }
}
