.logo2ColordarkIcon {
  width: 150px;
  height: auto;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.quote {
  margin: 0;
  align-self: stretch;
  height: 102px;
  position: relative;
  font-size: inherit;
  line-height: 140%;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
}
.avatarImageIcon {
  width: 56px;
  height: 56px;
  position: relative;
  border-radius: 50%;
  object-fit: cover;
}
.columnOneFooter {
  font-weight: 600;
}
.columnOneFooter,
.columnOneFooter1 {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}
.avatar,
.avatarContent,
.content,
.testimonial1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.avatarContent {
  align-self: stretch;
  align-items: flex-start;
}
.avatar,
.content,
.testimonial1 {
  align-items: center;
}
.avatar {
  width: 300px;
  gap: var(--gap-base);
  font-size: var(--text-regular-normal-size);
}
.content,
.testimonial1 {
  overflow: hidden;
  max-width: 100%;
}
.content {
  width: 768px;
  gap: var(--gap-13xl);
}
.testimonial1 {
  align-self: stretch;
  padding: var(--padding-93xl) var(--padding-45xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-5xl);
  color: var(--link-primary);
  font-family: var(--text-small-link);
}
@media screen and (max-width: 1125px) {
  .testimonial1 {
    gap: var(--gap-21xl);
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 800px) {
  .testimonial1 {
    padding-top: var(--padding-54xl);
    padding-bottom: var(--padding-54xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .quote {
    font-size: var(--font-size-lgi);
    line-height: 27px;
  }
  .content {
    gap: var(--gap-base);
  }
  .testimonial1 {
    gap: var(--gap-xl);
  }
}
