@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;600;700&display=swap");

/* Variables CSS pour permettre un changement dynamique des couleurs */
:root {
  --background-gradient-start: #ffffff; /* Blanc */
  --background-gradient-end: #ffffff; /* Blanc */
  --text-color: #333333;
  --header-color: #000000;
}

/* Assure que tout le fond de la page est blanc */
body {
  background-color: #ffffff; /* Fond de page blanc */
  margin: 0;
  padding: 0;
}

/* Encapsulation des styles sous .policyContainer pour éviter les styles globaux */
.policyContainer {
  margin: 0;
  background: #ffffff; /* Fond blanc pour la section si nécessaire */
  font-family: 'Roboto', sans-serif;
  color: var(--text-color);
  padding: var(--padding-45xl) var(--padding-xl);
  max-width: 900px;
  margin: 0 auto;
  text-align: justify;
  line-height: 1.6;
}

/* Style pour les titres H1 et H2 uniquement dans PrivacyPolicy */
.policyContainer h1 {
  text-align: center;
  font-size: var(--heading-desktop-h3-size);
  margin-bottom: var(--gap-xl);
  color: var(--header-color);
}

.policyContainer h2 {
  font-size: var(--font-size-5xl);
  margin-top: var(--gap-21xl);
  margin-bottom: var(--gap-sm);
  font-weight: 600;
  color: var(--header-color);
}

/* Styles pour les paragraphes, ul, li, et address encapsulés dans policyContainer */
.policyContainer p,
.policyContainer ul,
.policyContainer li,
.policyContainer address {
  font-size: var(--text-regular-normal-size);
  color: var(--text-color);
}
