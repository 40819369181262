.subheading {
  width: 73px;
  position: relative;
  line-height: 150%;
  font-weight: 600;
  display: none;
}
.heading {
  margin: 0;
  align-self: stretch;
  height: 116px;
  position: relative;
  font-size: var(--heading-desktop-h2-size);
  line-height: 120%;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
}
.content1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.button {
  position: relative;
  font-size: var(--text-regular-normal-size);
  line-height: 150%;
  font-family: var(--text-small-link);
  color: var(--link-primary);
  text-align: left;
}
.stylesecondarySmallfalse {
  cursor: pointer;
  border: 1px solid var(--link-primary);
  padding: var(--padding-xs) var(--padding-2xl);
  background-color: transparent;
  border-radius: var(--br-71xl);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.stylesecondarySmallfalse:hover {
  background-color: var(--color-darkslategray-200);
  border: 1px solid var(--color-darkslategray-100);
  box-sizing: border-box;
}
.button1 {
  position: relative;
  line-height: 150%;
  white-space: nowrap;
}
.iconChevronRight {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.stylelinkSmallfalseDark,
.vectorsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.vectorsWrapper {
  overflow: hidden;
}
.stylelinkSmallfalseDark {
  gap: var(--gap-5xs);
  cursor: pointer;
}
.actions,
.column {
  display: flex;
  justify-content: flex-start;
}
.actions {
  flex-direction: row;
  align-items: center;
  padding: var(--padding-base) 0 0;
  gap: var(--gap-5xl);
}
.column {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-13xl);
  min-width: 400px;
  max-width: 100%;
}
.iconRelume {
  width: 48px;
  height: 48px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.content2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.divider,
.heading1 {
  align-self: stretch;
  position: relative;
  line-height: 140%;
}
.divider {
  font-size: var(--text-regular-normal-size);
  line-height: 150%;
}
.content3 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-base);
  min-width: 343px;
}
.content3,
.timeline,
.timelineItem {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.timelineItem {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-21xl);
}
.timeline {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-base);
  min-width: 400px;
  font-size: var(--heading-desktop-h6-size);
}
.content,
.layout121 {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}
.content {
  flex: 1;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-61xl);
}
.layout121 {
  align-self: stretch;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: var(--padding-93xl) var(--padding-45xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--text-regular-normal-size);
  color: var(--link-primary);
  font-family: var(--text-small-link);
}
@media screen and (max-width: 1125px) {
  .layout121 {
    padding-top: var(--padding-54xl);
    padding-bottom: var(--padding-54xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 800px) {
  .heading {
    font-size: var(--font-size-19xl);
    line-height: 46px;
  }
  .column {
    gap: var(--gap-base);
  }
  .column,
  .content3 {
    min-width: 100%;
  }
  .timelineItem {
    flex-wrap: wrap;
    gap: var(--gap-xl);
  }
  .timeline {
    min-width: 100%;
  }
  .content,
  .layout121 {
    gap: var(--gap-21xl);
  }
  .layout121 {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .heading {
    font-size: var(--font-size-10xl);
    line-height: 35px;
  }
  .heading1 {
    font-size: var(--text-regular-normal-size);
    line-height: 22px;
  }
  .content,
  .layout121 {
    gap: var(--gap-xl);
  }
  .layout121 {
    padding-top: var(--padding-28xl);
    padding-bottom: var(--padding-28xl);
    box-sizing: border-box;
  }
}
