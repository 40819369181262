.chooseATopic,
.selectOne {
  position: relative;
  line-height: 150%;
}
.chooseATopic {
  align-self: stretch;
}
.selectOne {
  flex: 1;
  display: inline-block;
  max-width: calc(100% - 40px);
}
.iconChevronDown {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.iconfalse,
.input {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.iconfalse {
  border-radius: var(--br-71xl);
  background-color: var(--color-neutral-white);
  border: 1px solid var(--link-primary);
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-smi) var(--padding-xs)
    var(--padding-2xs);
  gap: var(--gap-base);
  color: var(--color-neutral-neutral);
}
.input {
  flex-direction: column;
  gap: var(--gap-5xs);
  flex-shrink: 0;
}
.whichBestDescribes {
  position: relative;
  line-height: 150%;
}
.content {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  max-width: 100%;
}
.checkbox,
.radios {
  box-sizing: border-box;
}
.radios {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base) 0;
  gap: var(--gap-base);
  max-width: 100%;
}
.checkbox {
  margin: 0;
  height: 20px;
  width: 20px;
  position: relative;
  border: 1px solid var(--link-primary);
  overflow: hidden;
  flex-shrink: 0;
}
.label {
  position: relative;
  line-height: 150%;
}
.selectedfalse {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 0 var(--padding-base);
  gap: var(--gap-5xs);
  font-size: var(--text-small-link-size);
}
.button {
  position: relative;
  font-size: var(--text-regular-normal-size);
  line-height: 150%;
  font-family: var(--text-small-link);
  color: var(--color-neutral-white);
  text-align: left;
}
.styleprimarySmallfalseDa {
  cursor: pointer;
  border: 1px solid var(--link-primary);
  padding: var(--padding-xs);
  background-color: var(--link-primary);
  width: 101px;
  height: 50px;
  border-radius: var(--br-71xl);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.styleprimarySmallfalseDa:hover {
  background-color: var(--color-darkslategray-100);
  border: 1px solid var(--color-darkslategray-100);
  box-sizing: border-box;
}
.form {
  width: 768px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--text-regular-normal-size);
  color: var(--link-primary);
  font-family: var(--text-small-link);
}

@keyframes successAnimation {
  0% { opacity: 0; transform: scale(0.9); }
  50% { opacity: 1; transform: scale(1.1); }
  100% { opacity: 1; transform: scale(1); }
}

.successMessage {
  display: none;
  background-color: #4BB543;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  animation: successAnimation 8s ease-in-out forwards;
}

.successMessage.show {
  display: block;
}

.messageContainer {
  text-align: center;
  margin-top: 20px;
}

.thankYouMessage {
  font-size: 1.2em;
  font-weight: bold;
}

.retryMessage {
  font-size: 1em;
  margin-top: 10px;
}

.retryLink {
  color: rgb(0, 0, 0);
  text-decoration: underline;
  cursor: pointer;
}
