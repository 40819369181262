.iconRelume {
  width: 48px;
  height: 48px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.divider {
  height: 102px;
  width: 2px;
  position: relative;
  background-color: var(--link-primary);
  border-right: 2px solid var(--link-primary);
  box-sizing: border-box;
}
.content,
.vectorsWrapper {
  display: flex;
  align-items: center;
}
.vectorsWrapper {
  flex: 1;
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-5xl);
}
.content {
  height: 164px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.columnCreditsDivider,
.heading {
  align-self: stretch;
  position: relative;
  line-height: 140%;
}
.columnCreditsDivider {
  font-size: var(--text-regular-normal-size);
  line-height: 150%;
}
.content1,
.timelineItem {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.content1 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-base);
  min-width: 343px;
}
.timelineItem {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-21xl);
  text-align: left;
  font-size: var(--heading-desktop-h6-size);
  color: var(--link-primary);
  font-family: var(--text-small-link);
}
@media screen and (max-width: 800px) {
  .content1 {
    min-width: 100%;
  }
  .timelineItem {
    flex-wrap: wrap;
    gap: var(--gap-xl);
  }
}
@media screen and (max-width: 450px) {
  .heading {
    font-size: var(--text-regular-normal-size);
    line-height: 22px;
  }
}
