.aboutUs {
  width: 100%;
  position: relative;
  background: linear-gradient(180deg, #ffe2c3, #f5f5f5);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  letter-spacing: normal;
}

@media screen and (max-width: 450px) {
  .aboutUs {
    gap: var(--gap-13xl);
    padding-top: var(--padding-6xl); /* Ajoute un padding en haut pour éviter que le contenu soit caché */
  }
}