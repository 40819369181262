.headerContent,
.heading {
  align-self: stretch;
  position: relative;
}
.heading {
  margin: 0;
  font-size: inherit;
  line-height: 120%;
  font-weight: 700;
  font-family: inherit;
}
.headerContent {
  height: 54px;
  font-size: var(--text-medium-normal-size);
  line-height: 150%;
  display: inline-block;
}
.sectionTitle {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.question,
.textContent {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}
.textContent {
  font-size: var(--text-regular-normal-size);
}
.listItem {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.question1 {
  position: relative;
  line-height: 150%;
}
.listItem1,
.question1,
.text {
  align-self: stretch;
}
.text {
  position: relative;
  font-size: var(--text-regular-normal-size);
  line-height: 150%;
}
.listItem1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.question2,
.text1 {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}
.text1 {
  font-size: var(--text-regular-normal-size);
}
.listItem2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.question3,
.text2 {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}
.text2 {
  font-size: var(--text-regular-normal-size);
}
.listItem3 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.question4,
.text3 {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}
.text3 {
  font-size: var(--text-regular-normal-size);
}
.list,
.listItem4 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.list {
  overflow: hidden;
  gap: var(--gap-29xl);
  text-align: left;
  font-size: var(--text-medium-normal-size);
}
.heading1 {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 130%;
  font-weight: 700;
  font-family: inherit;
}
.content1,
.heading1,
.link {
  align-self: stretch;
}
.link {
  position: relative;
  font-size: var(--text-medium-normal-size);
  line-height: 150%;
}
.content1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  flex-shrink: 0;
}
.button {
  position: relative;
  font-size: var(--text-regular-normal-size);
  line-height: 150%;
  font-family: var(--text-small-link);
  color: var(--link-primary);
  text-align: left;
}
.stylesecondarySmallfalse {
  cursor: pointer;
  border: 1px solid var(--link-primary);
  padding: var(--padding-xs) var(--padding-5xl);
  background-color: transparent;
  height: 50px;
  border-radius: var(--br-71xl);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.stylesecondarySmallfalse:hover {
  background-color: var(--color-darkslategray-200);
  border: 1px solid var(--color-darkslategray-100);
  box-sizing: border-box;
}
.content {
  width: 560px;
  gap: var(--gap-5xl);
  font-size: var(--font-size-13xl);
}
.content,
.faq7,
.maxWidth {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
.maxWidth {
  width: 768px;
  gap: var(--gap-61xl);
}
.faq7 {
  align-self: stretch;
  overflow: hidden;
  padding: var(--padding-93xl) var(--padding-45xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--heading-desktop-h2-size);
  color: var(--link-primary);
  font-family: var(--text-small-link);
}
@media screen and (max-width: 1325px) {
  .faq7 {
    padding-top: var(--padding-54xl);
    padding-bottom: var(--padding-54xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1125px) {
  .faq7 {
    gap: var(--gap-21xl);
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 800px) {
  .heading {
    font-size: var(--font-size-19xl);
    line-height: 46px;
  }
  .heading1 {
    font-size: var(--font-size-7xl);
    line-height: 33px;
  }
  .maxWidth {
    gap: var(--gap-21xl);
  }
  .faq7 {
    padding-top: var(--padding-28xl);
    padding-bottom: var(--padding-28xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .heading {
    font-size: var(--font-size-10xl);
    line-height: 35px;
  }
  .list {
    gap: var(--gap-5xl);
  }
  .heading1 {
    font-size: var(--font-size-lgi);
    line-height: 25px;
  }
  .faq7,
  .maxWidth {
    gap: var(--gap-xl);
  }
}

@media screen and (max-width: 450px) {
  .headerContent {
    margin-bottom: var(--gap-base); /* Ajoute un espace en bas de l'élément headerContent */
    padding-bottom: var(--padding-base); /* Ajoute un padding pour espacer cet élément des suivants */
    box-sizing: border-box; /* S'assure que padding et border sont inclus dans la largeur totale */
  }

  .question {
    margin-top: var(--gap-base); /* Ajoute un espace en haut de chaque question */
    margin-bottom: var(--gap-base); /* Ajoute un espace en bas de chaque question */
  }

  .faq7,
  .maxWidth {
    gap: var(--gap-45xl); /* Ajuste l'espacement général dans ces conteneurs */
  }
}
