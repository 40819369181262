.colorDark,
.column {
  height: 27px;
  overflow: hidden;
  color: #f7f7f7;
  border-color: #f7f7f7;
}
.colorDark {
  width: 63px;
  position: relative;
  flex-shrink: 0;
  display: none;
  color: #f7f7f7;
}
.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-image: url(/public/logoAlpaga_white.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  min-width: 151px;
  color: #f7f7f7;
  border-color: #f7f7f7;
}
.columnOne,
.linkOne {
  position: relative;
  line-height: 150%;
  cursor: pointer;
  color: #f7f7f7;
}
.columnOne {
  align-self: stretch;
  font-weight: 600;
  color: #f7f7f7;
}
.linkOne {
  flex: 1;
  color: #f7f7f7;
}
.link {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
  color: #f7f7f7;
  border-color: #f7f7f7;
}
.linkTwo {
  flex: 1;
  position: relative;
  line-height: 150%;
  cursor: pointer;
  color: #f7f7f7;
}
.link1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
  color: #f7f7f7;
  border-color: #f7f7f7;
}
.linkThree {
  flex: 1;
  position: relative;
  line-height: 150%;
  color: #f7f7f7;
}
.link2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
  color: #f7f7f7;
  border-color: #f7f7f7;
}
.linkFour {
  flex: 1;
  position: relative;
  line-height: 150%;
  color: #f7f7f7;
}
.link3 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-11xl) var(--padding-5xs) 0;
  color: #f7f7f7;
  border-color: #f7f7f7;
}
.linkFive {
  flex: 1;
  position: relative;
  line-height: 150%;
  color: #f7f7f7;
}
.column1,
.footerLinks,
.link4 {
  align-items: flex-start;
  justify-content: flex-start;
  color: #f7f7f7;
}
.link4 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  color: #f7f7f7;
}
.column1,
.footerLinks {
  display: flex;
  flex-direction: column;
  color: #f7f7f7;
}
.footerLinks {
  align-self: stretch;
  font-size: var(--text-small-link-size);
  color: #f7f7f7;
}
.column1 {
  flex: 1;
  overflow: hidden;
  gap: var(--gap-base);
  min-width: 151px;
  color: #f7f7f7;
  border-color: #f7f7f7;
}
.columnTwo,
.linkSix {
  position: relative;
  line-height: 150%;
  color: #f7f7f7;
}
.columnTwo {
  align-self: stretch;
  font-weight: 600;
  color: #f7f7f7;
}
.linkSix {
  flex: 1;
  color: #f7f7f7;
}
.link5 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
  color: #f7f7f7;
  border-color: #f7f7f7;
}
.linkSeven {
  flex: 1;
  position: relative;
  line-height: 150%;
  color: #f7f7f7;
}
.link6 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
  color: #f7f7f7;
  border-color: #f7f7f7;
}
.linkEight {
  flex: 1;
  position: relative;
  line-height: 150%;
  cursor: pointer;
  color: #f7f7f7;
}
.link7 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
  color: #f7f7f7;
  border-color: #f7f7f7;
}
.linkNine {
  flex: 1;
  position: relative;
  line-height: 150%;
  color: #f7f7f7;
}
.link8,
.linkTen {
  align-self: stretch;
  color: #f7f7f7;
}
.link8 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
  color: #f7f7f7;
  border-color: #f7f7f7;
}
.linkTen {
  width: 141px;
  position: relative;
  line-height: 150%;
  display: none;
  color: #f7f7f7;
}
.column2,
.footerLinks1,
.link9 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: #f7f7f7;
}
.link9 {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  color: #f7f7f7;
  border-color: #f7f7f7;
}
.column2,
.footerLinks1 {
  flex-direction: column;
  color: #f7f7f7;
}
.footerLinks1 {
  align-self: stretch;
  height: 111px;
  font-size: var(--text-small-link-size);
  color: #f7f7f7;
}
.column2 {
  flex: 1;
  overflow: hidden;
  gap: var(--gap-base);
  min-width: 151px;
  color: #f7f7f7;
  border-color: #f7f7f7;
}
.columnThree {
  align-self: stretch;
  height: 24px;
  font-weight: 600;
  display: inline-block;
  color: #f7f7f7;
}
.columnThree,
.linkEleven {
  position: relative;
  line-height: 150%;
  color: #f7f7f7;
}
.link10,
.linkEleven,
.linkTwelve {
  align-self: stretch;
  flex: 1;
  color: #f7f7f7;
}
.link10 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
  color: #f7f7f7;
  border-color: #f7f7f7;
}
.linkTwelve {
  position: relative;
  line-height: 150%;
  color: #f7f7f7;
}
.link11 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
  color: #f7f7f7;
  border-color: #f7f7f7;
}
.link11,
.link12,
.linkThirteen {
  align-self: stretch;
  flex: 1;
  color: #f7f7f7;
}
.linkThirteen {
  position: relative;
  line-height: 150%;
  color: #f7f7f7;
}
.link12 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
  color: #f7f7f7;
  border-color: #f7f7f7;
}
.linkFourteen {
  align-self: stretch;
  width: 171px;
  position: relative;
  line-height: 150%;
  display: inline-block;
  flex-shrink: 0;
  color: #f7f7f7;
}
.link13,
.linkFifteen {
  align-self: stretch;
  flex: 1;
  color: #f7f7f7;
}
.link13 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
  color: #f7f7f7;
  border-color: #f7f7f7;
}
.linkFifteen {
  position: relative;
  line-height: 150%;
  color: #f7f7f7;
}
.footerLinks2,
.link14 {
  align-self: stretch;
  flex: 1;
  display: flex;
  color: #f7f7f7;
}
.link14 {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
  color: #f7f7f7;
  border-color: #f7f7f7;
}
.footerLinks2 {
  flex-direction: column;
  font-size: var(--text-small-link-size);
  color: #f7f7f7;
}
.column3,
.footerLinks2,
.links {
  align-items: flex-start;
  justify-content: flex-start;
  color: #f7f7f7;
}
.column3 {
  height: 225px;
  width: 141px;
  overflow: hidden;
  display: none;
  flex-direction: column;
  gap: var(--gap-base);
  color: #f7f7f7;
  border-color: #f7f7f7;
}
.links {
  width: 684px;
  display: flex;
  flex-direction: row;
  gap: var(--gap-21xl);
  min-width: 684px;
  max-width: 100%;
  color: #f7f7f7;
}
.subscribe {
  font-weight: 600;
  color: #f7f7f7;
}
.joinOurNewsletter,
.subscribe {
  align-self: stretch;
  position: relative;
  line-height: 150%;
  color: #f7f7f7;
}
.subscribeParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  color: #f7f7f7;
}
.placeholder {
  width: 100%;
  border: 0;
  outline: 0;
  font-family: var(--text-small-link);
  font-size: var(--text-regular-normal-size);
  background-color: transparent;
  height: 24px;
  flex: 1;
  position: relative;
  line-height: 150%;
  color: #f7f7f7;
  text-align: left;
  display: inline-block;
  min-width: 205px;
  max-width: 100%;
}
.typedefault {
  flex: 1;
  background-color: transparent;
  border: 1px solid #f7f7f7;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-smi) var(--padding-xs)
    var(--padding-2xs);
  max-width: calc(100% - 137px);
  color: #f7f7f7;
}
.button {
  position: relative;
  font-size: var(--text-regular-normal-size);
  line-height: 150%;
  font-family: var(--text-small-link);
  color: #f7f7f7;
  text-align: left;
}
.stylesecondarySmallfalse {
  cursor: pointer;
  border: 1px solid #f7f7f7;
  padding: var(--padding-xs) var(--padding-5xl);
  background-color: transparent;
  height: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #f7f7f7;
}
.stylesecondarySmallfalse:hover {
  background-color: var(--color-darkslategray-200);
  border: 1px solid #f7f7f7;
  box-sizing: border-box;
  color: #f7f7f7;
}
.form {
  align-self: stretch;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  max-width: 100%;
  color: #f7f7f7;
}
.bySubscribingYou {
  align-self: stretch;
  position: relative;
  line-height: 150%;
  color: #f7f7f7;
}
.actions {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--text-tiny-normal-size);
  color: #f7f7f7;
}
.actions,
.content,
.newslatter {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  color: #f7f7f7;
}
.newslatter {
  width: 500px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  min-width: 500px;
  color: #f7f7f7;
}
.content {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  min-height: 248px;
  gap: var(--gap-xl);
  color: #f7f7f7;
}
.divider {
  align-self: stretch;
  height: 1px;
  position: relative;
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  box-sizing: border-box;
}
.relumeAllRights {
  position: relative;
  line-height: 150%;
  color: #f7f7f7;
}
.cookiesSettings,
.privacyPolicy,
.termsOfService {
  position: relative;
  text-decoration: underline;
  line-height: 150%;
  color: #f7f7f7;
}
.cookiesSettings,
.termsOfService {
  flex: 1;
  display: inline-block;
  min-width: 68px;
  color: #f7f7f7;
}
.credits1,
.footerLinks3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  max-width: 100%;
  color: #f7f7f7;
}
.footerLinks3 {
  flex: 1;
  align-items: flex-start;
  min-width: 224px;
  color: #f7f7f7;
}
.credits1 {
  width: 583px;
  align-items: center;
  color: #f7f7f7;
}
.iconFacebook,
.iconInstagram,
.iconLinkedin,
.iconX,
.iconYoutube {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
  color: #f7f7f7;
}
.credits,
.footer2,
.row,
.socialLinks {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
  color: #f7f7f7;
}
.credits,
.footer2,
.row {
  align-self: stretch;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
  color: #f7f7f7;
}
.credits,
.footer2 {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  font-size: var(--text-small-link-size);
  color: #f7f7f7;
}
.footer2 {
  overflow: hidden;
  align-items: center;
  background-color: transparent;
  padding: var(--padding-61xl) var(--padding-45xl);
  box-sizing: border-box;
  gap: var(--gap-61xl);
  text-align: left;
  font-size: var(--text-regular-normal-size);
  color: #f7f7f7;
  font-family: var(--text-small-link);
}
@media screen and (max-width: 1325px) {
  .links,
  .newslatter {
    flex: 1;
    color: #f7f7f7;
  }
  .content {
    flex-wrap: wrap;
    color: #f7f7f7;
  }
}
@media screen and (max-width: 1125px) {
  .links {
    min-width: 100%;
    color: #f7f7f7;
  }
  .row {
    flex-wrap: wrap;
    color: #f7f7f7;
  }
}
@media screen and (max-width: 800px) {
  .links {
    flex-wrap: wrap;
    gap: var(--gap-xl);
    color: #f7f7f7;
  }
  .newslatter {
    min-width: 100%;
    color: #f7f7f7;
  }
  .credits1 {
    flex-wrap: wrap;
    color: #f7f7f7;
  }
  .credits {
    gap: var(--gap-base);
    color: #f7f7f7;
  }
  .footer2 {
    gap: var(--gap-21xl);
    padding: var(--padding-33xl) var(--padding-13xl);
    box-sizing: border-box;
    color: #f7f7f7;
  }
}
@media screen and (max-width: 450px) {
  .footerLinks3 {
    flex-wrap: wrap;
    color: #f7f7f7;
  }
  .footer2 {
    gap: var(--gap-xl);
    color: #f7f7f7;
  }
}

.customLink {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex: 1;
}

.customLink:visited,
.customLink:active {
  color: inherit;
  text-decoration: none;
}

.successMessage {
  margin-top: 10px;
  color: green;
}

.errorMessage {
  margin-top: 10px;
  color: red;
}
