:root {
    --night-background: #1a1a1a00;
    --night-text-color: #ffffff;
    --night-link-primary: #ffffff;
    --night-border-color: #333333;
    --night-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .columnIcon {
    height: 50px;
    width: 150px;
    object-fit: contain;
    cursor: pointer;
  }
  
  .linkOne,
  .linkTwo,
  .linkAlt,
  .linkThree,
  .linkBlog {
    position: relative;
    line-height: 150%;
    white-space: nowrap;
    cursor: pointer;
    color: var(--night-text-color);
  }
  
  .column1 {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-13xl);
    max-width: 100%;
    margin-left: -120px; /* décaler légèrement tous les liens vers la gauche */
  }
  
  .button {
    position: relative;
    line-height: 150%;
    color: black; /* Ajoutez cette ligne pour que le texte du bouton soit noir */
  }
  
  .actions,
  .styleprimarySmalltrueDar {
    border-radius: var(--br-71xl);
    display: flex;
    flex-direction: row;
  }
  
  .styleprimarySmalltrueDar {
    flex: 1;
    background-color: var(--night-link-primary);
    border: 1px solid var(--night-link-primary);
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: var(--padding-5xs) var(--padding-lgi);
    transition: background-color 0.3s ease; /* Ajoute une transition douce */
    transition: border 0.3 ease;
  }
  
  .styleprimarySmalltrueDar:hover {
    background-color: rgb(0, 0, 0);
    border: 1px solid rgb(0, 0, 0);
  }
  
  .styleprimarySmalltrueDar:hover .button {
    color: rgb(255, 255, 255); /* Change la couleur du texte du bouton */
  }
  
  .actions {
    width: 100px; /* Assurez-vous que cette largeur est suffisante pour "CONTACT" */
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
    color: var(--night-text-color);
  }
  
  .column,
  .content,
  .navbar11 {
    display: flex;
    align-items: center;
    max-width: 100%;
  }
  
  .column {
    width: 650px;
    flex-direction: row;
    justify-content: center;
    gap: var(--gap-base);
  }
  
  .content,
  .navbar11 {
    align-self: stretch;
    flex-wrap: nowrap; /* Empêche les éléments de passer en dessous l'un de l'autre */
  }
  
  .content {
    flex: 1;
    /* Suppression de la bordure inférieure */
    box-sizing: border-box;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--padding-smi) var(--padding-41xl);
    gap: var(--gap-xl);
  }
  
  .navbar11 {
    height: 72px;
    background: var(--night-background);
    backdrop-filter: blur(10px); /* Ajout de flou gaussien */
    flex-direction: column;
    justify-content: flex-start;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    position: fixed;
    text-align: left;
    font-size: var(--text-regular-normal-size);
    color: var(--night-link-primary);
    font-family: var(--text-small-link);
    border-radius: 50px; /* Ajout de coins arrondis */
    box-shadow: var(--night-box-shadow); /* Ajout d'une ombre pour l'effet de flottement */
    margin: 15px; /* Ajout d'un espace autour pour séparer de la page */
  }
  
  @media screen and (max-width: 1125px) {
    .content {
      padding-left: var(--padding-11xl);
      padding-right: var(--padding-11xl);
      box-sizing: border-box;
    }
  }
  
  @media screen and (max-width: 800px) {
    .column1 {
      display: none;
      gap: var(--gap-base);
    }
    .column {
      width: 99px;
    }
  }
  
  /* Ajout des styles pour le menu mobile */
  .mobileMenuIcon {
    font-size: 24px;
    cursor: pointer;
    margin-left: auto;
  }
  
  .mobileMenu {
    position: absolute;
    top: 80px; /* Décale le menu légèrement vers le bas pour éviter le chevauchement */
    left: 0;
    right: 0;
    background: linear-gradient(
        180deg,
        rgba(23, 23, 23, 0.97),
        rgba(9, 9, 9, 0.99)
      ); /* Même dégradé que la navbar */
    backdrop-filter: blur(10px); /* Effet de flou gaussien */
    border-radius: 20px; /* Arrondir les coins */
    box-shadow: var(--night-box-shadow); /* Même ombre que la navbar */
    padding: 10px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box; /* Assure que le padding et la bordure sont inclus dans la largeur totale */
  }
  
  .mobileLink {
    padding: 10px;
    cursor: pointer;
    width: 100%;
    text-align: left;
    color: var(--night-text-color);
    border-radius: 10px; /* Arrondir les coins des liens individuels */
    transition: background-color 0.3s ease;
    box-sizing: border-box; /* Assure que le padding et la bordure sont inclus dans la largeur totale */
  }
  
  .mobileLink:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Ajoute un effet au survol */
  }
  
  @media screen and (min-width: 1020px) {
    .mobileMenu {
      display: none; /* Masque le menu mobile sur les grands écrans */
    }
  }
  