.arty-container {
  position: fixed;
  bottom: var(--gap-xl);
  right: var(--gap-xl);
  z-index: 1000;
  width: 400px;
  max-width: 80%;
  background-color: transparent;
  box-shadow: none;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  padding: var(--padding-sm);
  overflow: scroll;
}

.conversation {
  overflow-y: auto;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  gap: var(--gap-sm);
}

/* Message bubble styles */
.message {
  max-width: 70%;
  background-color: #f0f0f0;
  padding: var(--padding-sm);
  border-radius: 20px;
  margin-bottom: var(--gap-sm);
  position: relative;
  font-family: 'Roboto', sans-serif;
  backdrop-filter: blur(10px);
  color: black;
  word-wrap: break-word; /* Ensures text wraps within the bubble */
  overflow-wrap: break-word; /* Ensures text wraps within the bubble */
  white-space: pre-wrap; /* Preserves whitespace formatting */
  display: block; /* Ensure the content is in block layout */
}

/* User message styles */
.user-message {
  background-color: rgba(250, 207, 248, 0.399);
  color: black;
  align-self: flex-end;
}

/* Bot message styles with profile picture */
.bot-message {
  background-color: rgba(128, 210, 248, 0.5);
  color: black;
  align-self: flex-start;
  padding-left: 60px;
  position: relative;
  font-family: 'Roboto', sans-serif;
  border-radius: 20px;
  margin-bottom: var(--gap-sm);
  display: flex;
  align-items: center;
  display: inline; /* Ensure links do not cause block layout */
}

.bot-message::before {
  content: '';
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-image: url('/public/placeholder-image-3@2x.png');
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  display: inline; /* Ensure links do not cause block layout */
}

.bot-message a {
  color: #0000EE; /* Blue color for links */
  text-decoration: underline; /* Underline for links */
  display: inline; /* Ensure links do not cause block layout */
}

.bot-message a:hover {
  color: #551A8B; /* Darker blue when hovered */
  text-decoration: underline; /* Underline for links */
}

/* Input area styles */
.input-area {
  position: sticky;
  bottom: 0;
  display: flex;
  background: transparent;
  padding: var(--padding-sm);
  border-top: none;
}

/* Input field styles */
.input-field {
  flex-grow: 1;
  background: rgba(255, 255, 255, 0.9);
  padding: var(--padding-sm);
  border: 1px solid #ccc;
  border-radius: var(--br-71xl);
  margin-right: var(--gap-sm);
}

/* Typing indicator styles */
.typing-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  background-color: rgba(128, 210, 248, 0.5);
  color: black;
  align-self: flex-start;
  padding: 0 20px;
  border-radius: 20px;
  margin-bottom: var(--gap-sm);
}

/* Send button styles */
.input-area button {
  padding: var(--padding-sm);
  background-color: var(--link-primary);
  color: var(--color-neutral-white);
  border: none;
  border-radius: var(--br-71xl);
  cursor: pointer;
}

.input-area button:hover {
  background-color: var(--color-slategray);
}

.minimize-button {
  position: absolute;
  top: -10px;
  right: 15px;
  cursor: pointer;
  color: slategrey;
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 5px 10px;
}

.minimized-icon {
  position: fixed;
  right: 7%; /* Garde une distance fixe par rapport au bord droit */
  bottom: 7%; /* Garde une distance fixe par rapport au bas */
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-image: url('/public/placeholder-image-3@2x.png');
  background-size: cover;
  background-position: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  animation: rgbPulse 2s infinite;
}

@keyframes rgbPulse {
  0%, 100% {
    box-shadow: 0 0 5px 5px rgba(252, 126, 242, 0.345), 0 0 10px 10px rgba(200, 190, 190, 0.5);
  }
  33% {
    box-shadow: 0 0 5px 5px rgba(91, 195, 244, 0.608), 0 0 10px 10px rgba(255, 178, 71, 0.471);
  }
  66% {
    box-shadow: 0 0 5px 5px rgba(246, 144, 185, 0.7), 0 0 10px 10px rgba(129, 129, 255, 0.363);
  }
}
