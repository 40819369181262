/* Conteneur de la page */
.pageContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: #f6f6f6;
  z-index: 0;
}

/* Sidebar par défaut (grands écrans) */
.sidebar {
  position: fixed;
  top: 150px;
  left: 40px;
  width: 200px;
  max-height: 400px;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  overflow-y: auto;
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
}

/* Classe pour afficher la sidebar */
.fadeInOut {
  opacity: 1;
  transform: translateX(0);
}

/* Styles pour la liste de la sidebar */
.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  margin: 0;
  padding: 0;
}

/* Styles pour les liens de la sidebar */
.sidebarLink {
  font-size: 0.9rem;
  color: #a4a4a4;
  text-decoration: none;
  padding: 5px 0;
  display: block;
  transition: color 0.3s;
}

.sidebarLink:hover,
.activeLink {
  color: #000000;
}

/* Contenu principal centré */
.contentCentered {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
  box-sizing: border-box;
}

/* Conteneur du titre et de l'image */
.titleImageContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 65px;
  flex-wrap: wrap;
}

.articleTitleCentered {
  font-size: 2rem;
  color: rgb(16, 16, 16);
  max-width: 60%;
  flex: 1;
}

.imageContainer {
  width: 275px;
  height: auto;
  flex-shrink: 0;
}

.bannerImage {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 50px;
}

.articleTextCentered {
  font-size: 1rem;
  line-height: 1.6;
  color: rgb(16, 16, 16);
  margin-top: 20px;
}

/* BlogArticleDetail.module.css */
.shareButtonsContainer {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.shareButtonsContainer p {
  margin-right: 10px;
}

.shareButtonsContainer a {
  margin-right: 10px;
}

.shareButtonsContainer img {
  width: 32px;
  height: 32px;
}

/* Responsive Design */

/* Grands écrans */
@media screen and (min-width: 1024px) {
  .articleTextCentered {
    font-size: 1.2rem;
  }

  .articleTitleCentered {
    font-size: 3rem;
  }
}

/* Tablettes et écrans moyens */
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .articleTitleCentered {
    font-size: 2.5rem;
  }

  .imageContainer {
    width: 50%;
  }
}

/* Petits écrans */
@media screen and (max-width: 1023px) {
  .sidebar {
    display: none;
  }

  .articleTextCentered {
    font-size: 0.9rem;
  }

  .articleTitleCentered {
    font-size: 2rem;
    max-width: 100%;
    text-align: left;
  }

  .imageContainer {
    width: 50%;
    margin-top: 20px;
  }

  .titleImageContainer {
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (max-width: 1200px) {
  .sidebar {
    display: none;
  }
}

@media screen and (max-width: 544px) {
  .articleTitleCentered {
    font-size: 1.5rem;
    max-width: 100%;
    text-align: center;
  }

  .imageContainer {
    width: 75%;
    margin-top: 20px;
  }

  .titleImageContainer {
    flex-direction: column;
    align-items: center;
  }
}

/* JSON Styling */
pre, code {
  white-space: pre-wrap; /* Préserve les sauts de ligne et espaces */
  word-wrap: break-word; /* Évite les débordements */
  font-family: monospace; /* Police adaptée pour le code */
  line-height: 1.5; /* Espacement entre les lignes */
  background-color: #f4f4f4; /* Fond gris clair */
  padding: 10px; /* Ajoute un espace interne */
  border-radius: 5px; /* Arrondi des coins */
  border: 1px solid #ddd; /* Bordure légère */
  margin: 20px 0; /* Espace autour du bloc */
}
