@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;600;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --text-small-link: Roboto;

  /* font sizes */
  --text-small-link-size: 14px;
  --text-tiny-normal-size: 12px;
  --text-regular-normal-size: 16px;
  --text-medium-normal-size: 18px;
  --heading-desktop-h3-size: 40px;
  --font-size-13xl: 32px;
  --font-size-5xl: 24px;
  --font-size-lgi: 19px;
  --font-size-7xl: 26px;
  --font-size-37xl: 56px;
  --font-size-26xl: 45px;
  --font-size-15xl: 34px;
  --heading-desktop-h2-size: 48px;
  --font-size-19xl: 38px;
  --font-size-10xl: 29px;
  --heading-desktop-h6-size: 20px;

  /* Colors */
  --link-primary: #000;
  --color-neutral-white: #F7F7F7;
  --color-neutral-neutral: #666;
  --color-slategray-100: #80789e;
  --color-slategray: #585278;
  --color-darkslategray-100: #333;
  --color-darkslategray-200: rgba(51, 51, 51, 0.09);

  /* Gaps */
  --gap-xl: 20px;
  --gap-61xl: 80px;
  --gap-21xl: 40px;
  --gap-13xl: 32px;
  --gap-base: 16px;
  --gap-xs: 12px;
  --gap-5xl: 24px;
  --gap-69xl: 88px;
  --gap-25xl: 44px;
  --gap-3xl: 22px;
  --gap-45xl: 64px;
  --gap-5xs: 8px;
  --gap-9xs: 4px;
  --gap-29xl: 48px;
  --gap-sm: 14px;
  --gap-77xl: 96px;

  /* Paddings */
  --padding-61xl: 80px;
  --padding-45xl: 64px;
  --padding-33xl: 52px;
  --padding-13xl: 32px;
  --padding-xs: 12px;
  --padding-5xl: 24px;
  --padding-smi: 13px;
  --padding-2xs: 11px;
  --padding-5xs: 8px;
  --padding-11xl: 30px;
  --padding-93xl: 112px;
  --padding-xl: 20px;
  --padding-4xl: 23px;
  --padding-12xl: 31px;
  --padding-sm: 14px;
  --padding-29xl: 48px;
  --padding-135xl: 154px;
  --padding-58xl: 77px;
  --padding-6xl: 25px;
  --padding-41xl: 60px;
  --padding-lgi: 19px;
  --padding-12xs: 1px;
  --padding-8xs: 5px;
  --padding-28xl: 47px;
  --padding-base: 16px;
  --padding-54xl: 73px;
  --padding-4xs: 9px;
  --padding-2xl: 21px;
  --padding-3xl: 22px;
  --padding-mini: 15px;

  /* Border radiuses */
  --br-71xl: 90px;
  --br-81xl: 100px;
  --br-94xl: 113px;
}

/* Arty component styles */
.arty-container {
  position: fixed;
  bottom: var(--gap-xl);
  right: var(--gap-xl);
  z-index: 1000;
  width: 400px;
  max-width: 80%;
  background-color: transparent; /* Make the background transparent */
  box-shadow: none; /* Remove box shadow if not needed */
 
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: var(--padding-sm);
}

/* Header styles */
.arty-container .header {
  display: flex;
  justify-content: right; /* Center the profile picture */

  padding: 10px; /* Add some padding around the picture */
  background: none; /* Background color for the header */
}

/* Profile picture styles */
.arty-container .profile-pic {
  width: 50px; /* Adjust size as necessary */
  height: 50px; /* Adjust size as necessary */
  border-radius: 50%; /* Circle shape */
  cursor: pointer; /* Indicates the image is clickable */
  right: 10px;
}

/* Conversation area styles */
.conversation {
  max-height: 500px; /* Adjust the height as necessary */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: var(--gap-sm);
}

/* Message bubble styles */
.message {
  max-width: 70%;
  background-color: #f0f0f0; /* Light grey background */
  padding: var(--padding-sm);
  border-radius: 20px; /* Rounded corners for bubbles */
  margin-bottom: var(--gap-sm);
  position: relative; /* For absolute positioning of the profile picture */
  font-family: 'Roboto', sans-serif;
  backdrop-filter: blur(10px);
}

/* User message styles */
.message.user {
  background-color: rgba(250, 207, 248, 0.399); /* Powder blue with transparency */
  color: var(--color-darkslategray);
  align-self: flex-end;
  font-family: 'Roboto', sans-serif;
  backdrop-filter: blur(10px);
}

/* Bot message styles */
.message.bot {
  background-color: rgba(128, 210, 248, 0.5); /* Powder blue with transparency */
  color: var(--color-darkslategray); /* Adjust as needed */
  align-self: flex-start;
  padding-left: 60px; /* Make room for the profile picture */
  font-family: 'Roboto', sans-serif;
  backdrop-filter: blur(10px);
  
  
}

/* Profile picture styles */
.message.bot::before {
  content: '';
  position: absolute;
  left: 10px; /* Adjust the position as necessary */
  top: 50%;
  transform: translateY(-50%);
  width: 30px; /* Profile picture size */
  height: 30px; /* Profile picture size */
  background-image: url('/public/placeholder-image-3@2x.png');
  background-size: cover;
  background-position: center;
  border-radius: 50%;
}

/* Input area styles */
.input-area {
  
  padding: var(--padding-sm);
  background: rgba(255, 255, 255, 0); /* Adjust the transparency as needed */
  border-top: none; /* Remove the top border */
  display: flex;
}

/* Input field styles */
.input-area input {
  flex-grow: 1;
  background: rgba(255, 255, 255, 0.1);
  padding: var(--padding-sm);
  width: 100%; /* Make the input field full width */
  backdrop-filter: blur(10px);
  border: 1px solid #ccc;
  border-radius: var(--br-71xl);
  margin-right: var(--gap-sm);
}

/* Send button styles */
.input-area button {
  padding: var(--padding-sm);
  background-color: var(--link-primary);
  color: var(--color-neutral-white);
  border: none;
  border-radius: var(--br-71xl);
  cursor: pointer;
}

.input-area button:hover {
  background-color: var(--color-slategray);
}

