.heading {
  margin: 0;
  width: 768px;
  height: 96px;
  position: relative;
  font-size: inherit;
  line-height: 120%;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.iconRelume {
  width: 48px;
  height: 48px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.heading1 {
  margin: 0;
  align-self: stretch;
  height: 68px;
  position: relative;
  font-size: inherit;
  line-height: 140%;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
}
.ourTeamOf {
  margin: 0;
}
.text {
  height: 72px;
  position: relative;
  font-size: var(--text-regular-normal-size);
  line-height: 150%;
  display: inline-block;
}
.content,
.sectionTitle {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.content {
  align-items: center;
}
.button {
  position: relative;
  line-height: 150%;
  white-space: nowrap;
}
.iconChevronRight {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}
.stylelinkSmallfalseDark {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.action {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0 0;
  text-align: left;
  font-size: var(--text-regular-normal-size);
}
.column,
.layout238,
.row {
  display: flex;
  max-width: 100%;
}
.column {
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  min-width: 304px;
}
.layout238,
.row {
  align-self: stretch;
}
.row {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-29xl);
  font-size: var(--font-size-5xl);
}
.layout238 {
  border-radius: var(--br-71xl);
  background: linear-gradient(180deg, rgba(201, 193, 236, 0), #e4a9b7);
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-93xl) var(--padding-45xl);
  box-sizing: border-box;
  gap: var(--gap-61xl);
  text-align: center;
  font-size: var(--heading-desktop-h3-size);
  color: var(--link-primary);
  font-family: var(--text-small-link);
}
@media screen and (max-width: 800px) {
  .heading {
    font-size: var(--font-size-13xl);
    line-height: 38px;
  }
  .row {
    gap: var(--gap-5xl);
  }
  .layout238 {
    gap: var(--gap-21xl);
    padding: var(--padding-54xl) var(--padding-13xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .heading {
    font-size: var(--font-size-5xl);
    line-height: 29px;
  }
  .heading1 {
    font-size: var(--font-size-lgi);
    line-height: 27px;
  }
  .layout238 {
    gap: var(--gap-xl);
  }
}
