.mentoring {
  width: 100%;
  position: relative;
  background: linear-gradient(180deg, #c9c1ece3, #d0b7fb);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  letter-spacing: normal;
}

@media screen and (max-width: 450px) {
  .mentoring {
    padding-top: 50px; /* Ajoutez de l'espace en haut pour descendre le contenu */
    justify-content: flex-start; /* Assurez-vous que le contenu reste en haut après avoir ajouté le padding */
  }
}
