.message,
.typeYourMessage {
  align-self: stretch;
  position: relative;
  line-height: 150%;
  border: none;
  flex: 1; /* Ajoutez cette ligne pour étendre le texte sur toute la hauteur de la zone de texte */
  resize: none;
}
.footercontentIcon,
.messageTextArea {
  width: 2px;
  height: 2px;
  position: absolute;
  margin: 0 !important;
  right: 0;
  bottom: -0.2px;
  object-fit: contain;
}
.footercontentIcon {
  width: 6px;
  height: 6px;
  bottom: 0;
  z-index: 1;
}
.input,
.textArea {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.textArea {
  background-color: var(--color-neutral-white);
  border: 1px solid var(--link-primary);
  box-sizing: border-box;
  align-items: center;
  padding: var(--padding-xs) var(--padding-2xs);
  position: relative;
  min-height: 180px;
  color: var(--color-neutral-neutral);
}
.input {
  align-items: flex-start;
  gap: var(--gap-5xs);
  flex-shrink: 0;
  text-align: left;
  font-size: var(--text-regular-normal-size);
  color: var(--link-primary);
  font-family: var(--text-small-link);
}
