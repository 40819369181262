.placeholderImageIcon {
  align-self: stretch;
  height: 395px;
  position: relative;
  border-radius: var(--br-71xl);
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.jobTitle,
.name {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}
.name {
  font-weight: 600;
}
.jobTitle {
  font-size: var(--text-medium-normal-size);
}
.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.content,
.text,
.title {
  align-self: stretch;
}
.text {
  position: relative;
  font-size: var(--text-regular-normal-size);
  line-height: 150%;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.iconDribble,
.iconLinkedin,
.iconX {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}
.card,
.socialIcons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.card {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-5xl);
  max-width: 100%;
  text-align: center;
  font-size: var(--heading-desktop-h6-size);
  color: var(--link-primary);
  font-family: var(--text-small-link);
}
@media screen and (max-width: 450px) {
  .name {
    font-size: var(--text-regular-normal-size);
    line-height: 24px;
  }
}
