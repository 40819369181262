.subheading {
  position: relative;
  line-height: 150%;
  font-weight: 600;
}

.heading,
.loremIpsumDolor {
  align-self: stretch;
  position: relative;
}

.heading {
  margin: 0;
  font-size: inherit;
  line-height: 120%;
  font-weight: 700;
  font-family: inherit;
}

.loremIpsumDolor {
  font-size: var(--text-medium-normal-size);
  line-height: 150%;
}

.content {
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-5xl);
  font-size: var(--heading-desktop-h2-size);
}

.content,
.row,
.sectionTitle {
  display: flex;
  justify-content: flex-start;
}

.sectionTitle {
  width: 768px;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-base);
  max-width: 100%;
}

.row {
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-29xl);
  flex-wrap: wrap;
}

.placeholderImageIcon,
.placeholderImageIcon1 {
  width: 100%;
  max-width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 3 / 4;
  border-radius: var(--br-71xl);
}

.jobTitle,
.name {
  align-self: stretch;
  position: relative;
  line-height: 150%;
  display: inline-block;
}

.name {
  height: 30px;
  font-weight: 600;
}

.jobTitle {
  height: 54px;
  font-size: var(--text-medium-normal-size);
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.content3,
.text,
.title {
  align-self: stretch;
}

.text {
  height: 24px;
  position: relative;
  font-size: var(--text-regular-normal-size);
  line-height: 150%;
  display: inline-block;
}

.content3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}

.iconDribble,
.iconLinkedin,
.iconX {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.iconDribble,
.iconX {
  margin: 0;
}

.card,
.socialIcons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
}

.card {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-5xl);
  max-width: 100%;
  box-sizing: border-box;
}

.jobTitle1,
.name1 {
  align-self: stretch;
  position: relative;
  line-height: 150%;
  display: inline-block;
}

.name1 {
  height: 30px;
  font-weight: 600;
}

.jobTitle1 {
  height: 27px;
  font-size: var(--text-medium-normal-size);
}

.title1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.content4,
.text1,
.title1 {
  align-self: stretch;
}

.text1 {
  height: 48px;
  position: relative;
  font-size: var(--text-regular-normal-size);
  line-height: 150%;
  display: inline-block;
}

.content4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}

.iconDribble1,
.iconLinkedin1,
.iconX1 {
  margin: 0;
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.socialIcons1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
}

.card1,
.content2,
.row1 {
  justify-content: flex-start;
  max-width: 100%;
}

.card1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-5xl);
  max-width: 100%;
  box-sizing: border-box;
}

.content2,
.row1 {
  width: 100%;
  align-items: flex-start;
}

.row1 {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-29xl);
  width: 100%;
  padding-left: var(--padding-45xl);
  padding-right: var(--padding-45xl);
}

.content2 {
  overflow-x: visible;
  display: flex;
  flex-direction: column;
  gap: var(--gap-45xl);
}

.heading1,
.headingone {
  align-self: stretch;
  position: relative;
}

.heading1 {
  margin: 0;
  font-size: inherit;
  line-height: 130%;
  font-weight: 700;
  font-family: inherit;
}

.headingone {
  font-size: var(--text-medium-normal-size);
  line-height: 150%;
}

.content6 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  flex-shrink: 0;
}

.button {
  position: relative;
  line-height: 150%;
}

.stylesecondarySmallfalse {
  height: 52px;
  border: 1px solid var(--link-primary);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-4xs);
  text-align: left;
  font-size: var(--text-regular-normal-size);
}

.content1,
.content5,
.team6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}

.content5 {
  width: 768px;
  gap: var(--gap-5xl);
  font-size: var(--font-size-13xl);
}

.content1,
.team6 {
  gap: var(--gap-77xl);
  font-size: var(--heading-desktop-h6-size);
}

.team6 {
  border-radius: var(--br-94xl);
  background: linear-gradient(180deg, #ffe4c3, rgba(255, 171, 241, 0));
  padding: var(--padding-93xl) var(--padding-45xl);
  padding-left: var(--padding-45xl);
  padding-right: var(--padding-45xl);
  box-sizing: border-box;
  gap: var(--gap-61xl);
  text-align: center;
  font-size: var(--text-regular-normal-size);
  color: var(--link-primary);
  font-family: var(--text-small-link);
  overflow: visible;
  width: 100%;
}

@media screen and (max-width: 1325px) {
  .team6 {
    padding-top: var(--padding-54xl);
    padding-bottom: var(--padding-54xl);
    padding-left: var(--padding-45xl);
    padding-right: var(--padding-45xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 1125px) {
  .row1 {
    justify-content: center;
    flex-wrap: wrap;
    gap: var(--gap-21xl);
    padding-left: var(--padding-45xl);
    padding-right: var(--padding-45xl);
  }
}

@media screen and (max-width: 800px) {
  .row1 {
    flex-direction: column;
    gap: var(--gap-13xl);
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
  }

  .card,
  .card1 {
    width: 100%;
    margin-bottom: var(--gap-13xl);
  }

  .placeholderImageIcon,
  .placeholderImageIcon1 {
    width: 100%;
    height: auto;
    aspect-ratio: 3 / 4;
  }
}

@media screen and (max-width: 450px) {
  .heading {
    font-size: var(--font-size-10xl);
    line-height: 35px;
  }

  .name,
  .name1 {
    font-size: var(--text-regular-normal-size);
    line-height: 24px;
  }

  .content2 {
    gap: var(--gap-base);
  }

  .row1 {
    gap: var(--gap-base);
    padding-left: var(--padding-base);
    padding-right: var(--padding-base);
  }

  .heading1 {
    font-size: var(--font-size-lgi);
    line-height: 25px;
  }

  .content1 {
    gap: var(--gap-5xl);
  }

  .team6 {
    gap: var(--gap-xl);
  }
}

/* Blinking cursor animation */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blinkingCursor {
  display: inline-block;
  width: 0.2ch;
  height: 1em;
  background-color: black;
  animation: blink 1s step-end infinite;
}
